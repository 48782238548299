import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import DB from 'utiles/db'
import { Alert, Confirm } from 'utiles/functions'
import Back from 'componentes/back'
const gatherers = new DB('gatherers')
const ecas = new DB('ecas')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: [],
			name: ''
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		gatherers.query('#eca = :e', { ':e': this.props.params.uuid }, { '#eca': 'eca' }).then(data => {
			if (data.status) {
				this.setState({ data: data.data.Items, loading: false })
			} else {
				Alert('Tenemos problemas de conexión', 'danger')
			}
		})
		ecas.find({ uuid: this.props.params.uuid }).then(data => {
			if (data.status) {
				this.setState({
					loading: false,
					name: data.data.Item.name
				})
			}
		})
	}

	delete(uuid) {
		Confirm('¿Realmente deseas eliminar este recolector?', async () => {
			this.setState({ loading: true })
			await gatherers.delete({ uuid })
			Alert('Recolector eliminado')
			this.setState({ loading: true }, () => {
				this.init()
			})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Recolectores</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to={`/admin/settings/gatherers/create/${this.props.params.uuid}`} className="btn">
							Nuevo recolector
						</NavLink>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Teléfono</th>
								<th>ECA</th>
								<th>Estado</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(recolector => (
								<tr key={recolector.uuid}>
									<td>{recolector.name}</td>
									<td>{recolector.phone}</td>
									<td>{this.state.name}</td>
									<td>
										<span className={`status ${recolector.status === 'Activo' ? 'Activo' : 'Inactivo'}`}>
											{recolector.status === 'Activo' ? 'Activo' : 'Inactivo'}
										</span>
									</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('admin/settings/gatherers/edit/' + recolector.uuid + '/' + this.props.params.uuid)
											}}
											className="btn-edit">
											Editar
										</a>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.delete(recolector.uuid)
											}}
											className="btn-delete">
											Eliminar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default withParams(Page)
