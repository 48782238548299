import { Component } from 'react'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import withParams from 'componentes/WithParams'
import DB from 'utiles/db'
import { v4 as uuidv4 } from 'uuid'
import Back from 'componentes/back'
const gatherers = new DB('gatherers')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('phone') !== '') {
			this.setState({ loading: true }, () => {
				gatherers
					.create({
						uuid: uuidv4(),
						name: data.get('name'),
						phone: data.get('phone'),
						eca: this.props.params.uuid,
						status: 'Activo'
					})
					.then(r => {
						if (r.status) {
							this.props.navigate('/admin/settings/gatherers/' + this.props.params.uuid)
							Alert('Recolector registrado con éxito')
						} else {
							this.setState({ loading: false }, () => {
								Alert('Tenemos problemas de conexión, intentalo de nuevo', 'danger')
							})
						}
					})
			})
		} else {
			Alert('Ingresa la información requerida (*)', 'danger')
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Recolectores</h2>
						<span>Nuevo</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
							<Input type="text" title="Nombre del recolector *" placeholder="Ingresa el nombre" name="name" />
						</div>
						<div className="column" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
							<Input type="number" title="Teléfono del recolector *" placeholder="Ingresa el teléfono" name="phone" />
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(Page)
