import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import AnimateScreen from 'componentes/animateScreen'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import { Alert, Confirm } from 'utiles/functions'
const typeReport = new DB('type_report')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: []
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		typeReport.getAll().then(data => {
			if (data.status) {
				this.setState({ data: data.data.Items, loading: false })
			} else {
				this.setState({ loading: false })
				Alert('Tenemos problemas de conexión', 'danger')
			}
		})
	}

	deleteType(uuid) {
		Confirm('¿Realmente desea eliminar este tipo de reporte?', () => {
			this.setState({ loading: true }, () => {
				typeReport.delete({ uuid }).then(r => {
					if (r.status) {
						this.setState({ loading: false })
						this.init()
					} else {
						console.log(r)
						this.setState({ loading: false })
						Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					}
				})
			})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/settings" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Tipos de reportes</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/admin/typealerts/create" className="btn">
							Nuevo tipo de reporte
						</NavLink>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Tipo</th>
								<th>Estado</th>
								<th>Puntos</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(type => (
								<tr key={type.uuid}>
									<td>{type.name}</td>
									<td>
										<span className={`status ${type.status ? 'Activo' : 'Inactivo'}`}>{type.status ? 'Activo' : 'Inactivo'}</span>
									</td>
									<td>{type.points}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('admin/typealerts/edit/' + type.uuid)
											}}
											className="btn-edit">
											Editar
										</a>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.deleteType(type.uuid)
											}}
											className="btn-delete">
											Eliminar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
