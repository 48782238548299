import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const typeReport = new DB('type_report')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			status: true,
			name: '',
			points: ''
		}
	}

	componentDidMount() {
		typeReport.get({ uuid: this.props.params.uuid }).then(data => {
			if (data.status) {
				this.setState({
					status: data.data.Item.status,
					name: data.data.Item.name,
					points: data.data.Item.points,
					loading: false
				})
			} else {
				console.log(data)
				this.setState({ loading: false })
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
			}
		})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		this.setState({ loading: true }, () => {
			if (data.get('name') !== '' && data.get('points') !== '') {
				typeReport
					.update(
						{
							name: data.get('name'),
							points: data.get('points'),
							status: this.state.status
						},
						{ uuid: this.props.params.uuid }
					)
					.then(r => {
						if (r.status) {
							this.props.navigate('/admin/typealerts')
							Alert('Tipo de alerta actualizada con exito')
						} else {
							this.setState({ loading: false }, () => {
								Alert('Tenemos problemas de conexión, intentalo de nuevo', 'danger')
							})
						}
					})
			} else {
				Alert('Ingresa la información requerida (*)', 'danger')
			}
		})
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/typealerts" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Tipos de alertas</h2>
						<span>Editar</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
							<Input type="text" title="Titulo del tipo de alerta *" value={this.state.name} placeholder="Ingresa el nombre" name="name" />
							<Input type="number" title="Puntos para el usuario *" value={this.state.points} placeholder="0" name="points" />
							<Input
								type="checkbox"
								title="Estado (No publicado / Publicado)"
								name="status"
								change={v => this.setState({ status: v })}
								active={this.state.status}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(Page)
