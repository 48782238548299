import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'
import { NavLink } from 'react-router-dom'
import { Alert, deleteImage, Confirm } from 'utiles/functions'
import DB from 'utiles/db'
import e from 'utiles/settings'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Loader from 'componentes/loader'
const banners = new DB('banners')

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 ${8}px 0`,
	borderRadius: 4,
	borderWidth: 1,
	borderColor: '#eaeaea',
	borderStyle: 'solid',
	width: '100%',
	background: isDragging ? '#2d9985a1' : '#fff',
	...draggableStyle
})
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: [],
			changeOrder: false
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		banners.getAll().then(data => {
			if (data.status) {
				// ordeno el array por index
				const bannersordenados = data.data.Items.sort((a, b) => a.index - b.index)

				this.setState({ data: bannersordenados, loading: false })
			} else {
				this.setState({ loading: false })
				Alert('Tenemos problemas de conexión', 'danger')
			}
		})
	}

	deleteBanner(uuid, image) {
		Confirm('¿Realmente desea eliminar este banner', () => {
			this.setState({ loading: true }, () => {
				// eliminar imagen

				deleteImage(image)

				// eliminar banner
				banners.delete({ uuid }).then(r => {
					if (r.status) {
						this.setState({ loading: false })
						this.init()
					} else {
						console.log(r)
						this.setState({ loading: false })
						Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					}
				})
			})
		})
	}

	async updateOrder() {
		this.setState({ loading: true })
		const listadoBanners = this.state.data
		const ps = []
		for (const banner of listadoBanners) {
			ps.push(banners.update({ index: banner.index }, { uuid: banner.uuid }))
		}
		await Promise.all(ps)
		this.setState({ loading: false, changeOrder: false })
	}

	// Orden de los banners
	onDragEnd(result, banners) {
		if (!result.destination) {
			return
		}
		let items = reorder(banners, result.source.index, result.destination.index)
		items = items.map((item, i) => {
			item.index = i
			return item
		})
		this.setState({ data: items, changeOrder: true })
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/settings" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Banner de imagenes</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						{this.state.changeOrder ? (
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.updateOrder()
								}}
								className="btn">
								Guardar ajustes
							</a>
						) : (
							<NavLink to="/admin/banners/create" className="btn">
								Nueva imagen
							</NavLink>
						)}
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>

					<DragDropContext onDragEnd={r => this.onDragEnd(r, this.state.data)}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{this.state.data.map((item, index) => (
										<Draggable key={item.uuid} draggableId={item.uuid} index={index}>
											{(provided, snapshot) => (
												<div
													className="item-drag dragBanners"
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
													<div>
														<div className="container-dragger">
															<span></span>
															<span></span>
															<span></span>
														</div>
														<NavLink className="item-pro">
															<img src={e.urlBucket + item.image} />
														</NavLink>
													</div>
													<div>{item.link !== '' ? item.link : 'N/A'}</div>
													<div>
														<span className={`status ${item.status ? 'Activo' : 'Inactivo'}`}>{item.status ? 'Activo' : 'Inactivo'}</span>
													</div>
													<div>
														<a
															href="#"
															onClick={event => {
																event.preventDefault()
																this.props.navigate('admin/banners/edit/' + item.uuid)
															}}
															className="btn-edit">
															Editar
														</a>
														<a
															href="#"
															onClick={event => {
																event.preventDefault()
																this.deleteBanner(item.uuid, item.image)
															}}
															className="btn-delete">
															Eliminar
														</a>
													</div>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
