import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage } from 'utiles/functions'
import Modal from 'componentes/modal'
import DB from 'utiles/db'
import { v4 as uuidv4 } from 'uuid'
const levels = new DB('levels')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true,
			icono: '',
			iconoPremio: '',
			modalPremio: false,
			premios: [],
			premioEdit: {},
			modalEditPremio: false
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('points') !== '' && this.state.premios.length > 0 && this.state.icono !== '') {
			this.setState({ loading: true }, async () => {
				try {
					const url = await uploadImage(this.state.icono, 'level_')
					const premios = {}
					for (const premio of this.state.premios) {
						const iconoPremio = await uploadImage(premio.icono, 'premio_')
						premios[uuidv4()] = {
							name: premio.name,
							icono: iconoPremio
						}
					}
					const dataLevel = {
						uuid: uuidv4(),
						name: data.get('name'),
						points: data.get('points'),
						description: data.get('description'),
						status: this.state.status,
						icono: url,
						premios
					}
					const r = await levels.create(dataLevel)
					if (r.status) {
						this.setState({ loading: false })
						this.props.navigate('admin/levels')
						Alert('Nivel registrado correctamente.')
					} else {
						console.log(r)
						this.setState({ loading: false })
						Alert('¡Ooops!, tenemos un problemas para crear el nivel, intenta de nuevo', 'danger')
					}
				} catch (err) {
					console.log(err)
					this.setState({ loading: false })
					Alert('¡Ooops!, tenemos un problemas para crear el nivel, intenta de nuevo', 'danger')
				}
			})
		} else {
			Alert('Ingresa la información requerida ( * )', 'danger')
		}
	}

	addPremio(e) {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (data.get('name') !== '' && this.state.iconoPremio !== '') {
			const premios = this.state.premios
			premios.push({
				name: data.get('name'),
				icono: this.state.iconoPremio
			})
			this.setState({
				premios,
				iconoPremio: '',
				modalPremio: false
			})
		} else {
			Alert('Ingresa la información requerida ( * )', 'danger')
		}
	}

	modalAddPremio() {
		return (
			<Modal active={this.state.modalPremio}>
				<form action="#" onSubmit={e => this.addPremio(e)}>
					<div className="title-page">
						<div className="title">
							<h2>Agregar premio al nivel</h2>
						</div>
					</div>
					<Input type="image" title="Icono *" img={this.state.iconoPremio} change={image => this.setState({ iconoPremio: image })} />
					<Input type="text" title="Nombre del premio *" placeholder="Ingresa el nombre" name="name" />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalPremio: false, iconoPremio: '' })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	updatePremio(e) {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (data.get('name') !== '' && this.state.iconoPremio !== '') {
			const premios = this.state.premios
			premios[this.state.premioEdit.index] = {
				name: data.get('name'),
				icono: this.state.iconoPremio
			}
			this.setState({
				premios,
				iconoPremio: '',
				premioEdit: {},
				modalEditPremio: false
			})
		} else {
			Alert('Ingresa la información requerida ( * )', 'danger')
		}
	}

	modalEditPremio() {
		return (
			<Modal active={this.state.modalEditPremio}>
				<form action="#" onSubmit={e => this.updatePremio(e)}>
					<div className="title-page">
						<div className="title">
							<h2>Editar premio</h2>
						</div>
					</div>
					<Input type="image" title="Icono *" img={this.state.iconoPremio} simple change={image => this.setState({ iconoPremio: image })} />
					<Input type="text" title="Nombre del premio *" value={this.state.premioEdit.name} placeholder="Ingresa el nombre" name="name" />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalEditPremio: false, iconoPremio: '' })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.modalAddPremio()}
				{this.modalEditPremio()}
				<form action="#" onSubmit={this.handleSubmit}>
					<Loader active={this.state.loading} message="Cargando..." />
					<div className="title-page">
						<div className="title">
							<NavLink to="/admin/levels" className="back">
								<img src={require('img/back.svg').default} width={25} height={25} />
							</NavLink>
							<h2>Niveles</h2>
							<span>Nuevo nivel</span>
						</div>
						<div className="btns">
							<button className="btn" type="submit">
								Guardar
							</button>
						</div>
					</div>
					<div className="card">
						<div className="columns">
							<div className="column">
								<Input type="text" title="Nombre del nivel *" placeholder="Ingresa el nombre del nivel" name="name" />
								<Input type="number" title="Puntos del nivel *" placeholder="Cantidad de puntos para alcanzar el nivel." name="points" />
								<Input type="textarea" title="Descripcion del nivel" placeholder="..." name="description" />
							</div>
							<div className="column">
								<Input type="image" title="Icono *" img={this.state.icono} change={image => this.setState({ icono: image })} />
								<Input type="checkbox" title="Estado del nivel" name="status" change={v => this.setState({ status: v })} active={this.state.status} />
							</div>
						</div>
						<div className="divider"></div>
						<div className="title-page">
							<div className="title">
								<h2>Premios del nivel *</h2>
							</div>
							{this.state.premios.length < 5 && (
								<div className="btns">
									<a
										className="btn"
										href="#"
										onClick={e => {
											e.preventDefault()
											this.setState({ modalPremio: true })
										}}>
										Agregar premio
									</a>
								</div>
							)}
						</div>
						{this.state.premios.length > 0 && (
							<>
								<div className="divider"></div>
								<div className="container-premios">
									{this.state.premios.map((p, i) => (
										<div className="premio-item" key={i}>
											<div className="container-img">
												<img src={p.icono} />
											</div>
											<span>{p.name}</span>
											<div className="btns">
												<a
													href="#"
													className="btn"
													onClick={e => {
														e.preventDefault()
														const premios = this.state.premios.filter(pp => pp !== p)
														this.setState({ premios })
													}}>
													Eliminar
												</a>
												<a
													href="#"
													className="btn"
													onClick={e => {
														e.preventDefault()
														this.setState({
															modalEditPremio: true,
															iconoPremio: p.icono,
															premioEdit: {
																index: this.state.premios.indexOf(p),
																...p
															}
														})
													}}>
													Editar
												</a>
											</div>
										</div>
									))}
								</div>
							</>
						)}
						<div className="divider"></div>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</>
		)
	}
}
