import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom'

// screens
import Login from 'screens/login'

// // 			ADMIN
// // ------------------------
import Admin from 'screens/admin'
import HomeAdmin from 'screens/inicioadmin'

// users
import Users from 'screens/users/index'
import UserShow from 'screens/users/show'
import CreateCollector from 'screens/users/createCollector'
// import UsersEdit from 'screens/users/edit';

// collections
import Colections from 'screens/collections/index'
// import ColectionsCreate from 'screens/collections/create';
// import ColectionsEdit from 'screens/collections/edit';

// Reportes
import Alerts from 'screens/alerts/index'
import AlertsEdit from 'screens/alerts/edit'
// import AlertsCreate from 'screens/alerts/create';

// ecommerce
import Ecommerce from 'screens/ecommerce/index'
// import EcommerceCreate from 'screens/ecommerce/create';
// import EcommerceEdit from 'screens/ecommerce/edit';

// elearning
import Elearning from 'screens/elearning/index'
// import ElearningCreate from 'screens/elearning/create';
// import ElearningEdit from 'screens/elearning/edit';

// campains
import Campains from 'screens/campains/index'
import CampainsCreate from 'screens/campains/create'
// import CampainsEdit from 'screens/campains/edit';

// Estaditicas
import Stats from 'screens/stats/index'

import NeedCollector from 'screens/need_collector/index'
import NeedCollectorShow from 'screens/need_collector/edit'
// import NeedCollector from 'screens/need_collector/index'
// import Profile from 'screens/profile';

/*
 
 CONFIGURACION
 -------------

*/
import Settings from 'screens/settings/index'
// ciudades
import Cities from 'screens/cities/index'
import CitiesCreate from 'screens/cities/create'
import CitiesEdit from 'screens/cities/edit'
// barrios
import Neighborhood from 'screens/neighborhood/index'
import NeighborhoodCreate from 'screens/neighborhood/create'
import NeighborhoodEdit from 'screens/neighborhood/edit'
// ECAS
import Ecas from 'screens/ecas/index'
import EcasCreate from 'screens/ecas/create'
import EcasEdit from 'screens/ecas/edit'
// RECOLECTORES
import Gatherers from 'screens/gatherers/index'
import GatherersCreate from 'screens/gatherers/create'
import GatherersEdit from 'screens/gatherers/edit'
// niveles
import Levels from 'screens/levels/index'
import LevelsCreate from 'screens/levels/create'
import LevelsEdit from 'screens/levels/edit'
import LevelsRaking from 'screens/levels/ranking'
// tipos de resoduos
import TypeWastes from 'screens/typewaste/index'
import TypeWastesCreate from 'screens/typewaste/create'
import TypeWastesEdit from 'screens/typewaste/edit'
// tipos de alertas
import TypeAlerts from 'screens/typealerts/index'
import TypeAlertsCreate from 'screens/typealerts/create'
import TypeAlertsEdit from 'screens/typealerts/edit'
// banner
import Banners from 'screens/banners/index'
import BannersEdit from 'screens/banners/create'
import BannersCreate from 'screens/banners/edit'

export default function AnimatedRoutes() {
	const location = useLocation()
	const navigate = useNavigate()
	const [user, setUser] = useState(null)
	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route path="/" element={<Login setUser={setUser} navigate={navigate} />} />

				{/* Dashboard de superadministrador */}
				<Route path="/admin" element={<Admin user={user} setUser={setUser} navigate={navigate} />}>
					{/* Home */}
					<Route path="inicio" element={<HomeAdmin user={user} navigate={navigate} />} />

					{/* Users */}
					<Route path="users" element={<Users navigate={navigate} />} />
					<Route path="users/show/:user" element={<UserShow navigate={navigate} />} />
					<Route path="users/create/collector" element={<CreateCollector navigate={navigate} />} />

					{/* Alertas */}
					<Route path="alerts" element={<Alerts navigate={navigate} />} />
					<Route path="alerts/edit/:uuid" element={<AlertsEdit navigate={navigate} />} />

					{/* Recolección */}
					<Route path="need_collector/" element={<NeedCollector navigate={navigate} user={user} />} />
					<Route path="need_collector/:uuid" element={<NeedCollectorShow navigate={navigate} user={user} />} />

					{/* <Route path="need_collector" element={<NeedCollector navigate={navigate} user={user}/>}/> */}

					<Route path="collections" element={<Colections navigate={navigate} />} />
					<Route path="ecommerce" element={<Ecommerce navigate={navigate} />} />
					<Route path="elearning" element={<Elearning navigate={navigate} />} />

					<Route path="campains" element={<Campains navigate={navigate} />} />
					<Route path="campains/create" element={<CampainsCreate navigate={navigate} />} />

					{/* Recolecion */}
					<Route path="collections" element={<Colections navigate={navigate} />} />

					{/* Reportes */}
					<Route path="alerts" element={<Alerts navigate={navigate} />} />
					<Route path="alerts/edit/:uuid" element={<AlertsEdit navigate={navigate} />} />

					{/* E-commerce */}
					<Route path="ecommerce" element={<Ecommerce navigate={navigate} />} />

					{/* E-learning */}
					<Route path="elearning" element={<Elearning navigate={navigate} />} />

					{/* Estadisticas */}
					<Route path="stats" element={<Stats navigate={navigate} />} />

					{/* CONFIGURACION */}
					<Route path="settings" element={<Settings navigate={navigate} />} />
					{/* ciudades */}
					<Route path="settings/cities" element={<Cities navigate={navigate} />} />
					<Route path="settings/cities/create" element={<CitiesCreate navigate={navigate} />} />
					<Route path="settings/cities/edit/:uuid" element={<CitiesEdit navigate={navigate} />} />
					{/* barrios */}
					<Route path="settings/cities/neighborhood/:uuid" element={<Neighborhood navigate={navigate} />} />
					<Route path="settings/cities/neighborhood/create/:uuid" element={<NeighborhoodCreate navigate={navigate} />} />
					<Route path="settings/cities/neighborhood/edit/:uuid/:city" element={<NeighborhoodEdit navigate={navigate} />} />
					{/* ecas */}
					<Route path="settings/ecas" element={<Ecas navigate={navigate} />} />
					<Route path="settings/ecas/create" element={<EcasCreate navigate={navigate} />} />
					<Route path="settings/ecas/edit/:uuid" element={<EcasEdit navigate={navigate} />} />
					{/* Recolectores */}
					<Route path="settings/gatherers/:uuid" element={<Gatherers navigate={navigate} />} />
					<Route path="settings/gatherers/create/:uuid" element={<GatherersCreate navigate={navigate} />} />
					<Route path="settings/gatherers/edit/:uuid/:eca" element={<GatherersEdit navigate={navigate} />} />
					{/* Niveles */}
					<Route path="levels" element={<Levels navigate={navigate} />} />
					<Route path="levels/ranking" element={<LevelsRaking navigate={navigate} />} />
					<Route path="levels/create" element={<LevelsCreate navigate={navigate} />} />
					<Route path="levels/edit/:uuid" element={<LevelsEdit navigate={navigate} />} />
					{/* tipos de residuos */}
					<Route path="typewaste" element={<TypeWastes navigate={navigate} />} />
					<Route path="typewaste/create" element={<TypeWastesCreate navigate={navigate} />} />
					<Route path="typewaste/edit/:uuid" element={<TypeWastesEdit navigate={navigate} />} />
					{/* Tipos de alertas */}
					<Route path="typealerts" element={<TypeAlerts navigate={navigate} />} />
					<Route path="typealerts/create" element={<TypeAlertsCreate navigate={navigate} />} />
					<Route path="typealerts/edit/:uuid" element={<TypeAlertsEdit navigate={navigate} />} />
					{/* Banner */}
					<Route path="banners" element={<Banners navigate={navigate} />} />
					<Route path="banners/create" element={<BannersEdit navigate={navigate} />} />
					<Route path="banners/edit/:uuid" element={<BannersCreate navigate={navigate} />} />
				</Route>
			</Routes>
		</AnimatePresence>
	)
}
