import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const bcrypt = require('bcryptjs')
const users = new DB('users')
const cities = new DB('cities')
const waste = new DB('waste')
const neighborhood = new DB('neighborhood')
const ecas = new DB('ecas')
const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			city: '',
			neighborhood: '',
			wastesEca: [],
			cities: [],
			neighborhoods: [],
			wastes: [],
			status: true,
			data: [],
			user: ''
		}
	}

	componentDidMount() {
		cities.getAll().then(data => {
			if (data.status) {
				this.setState(
					{
						cities: data.data.Items.map(c => {
							return { val: c.uuid, text: c.name }
						})
					},
					() => {
						this.getNeighborhood()
					}
				)
			} else {
				this.setState({ loading: false })
				Alert('Tenemos problemas de conexión', 'danger')
			}
		})
		waste.getAll().then(data => {
			if (data.status) {
				this.setState({ wastes: data.data.Items })
			}
		})

		ecas.find({ uuid: this.props.params.uuid }).then(data => {
			if (data.status) {
				this.setState({
					loading: false,
					data: data.data.Item,
					city: data.data.Item.city.uuid,
					neighborhood: data.data.Item.neighborhood.uuid,
					status: data.data.Item.status,
					user: data.data.Item.user,
					wastesEca: data.data.Item.wastes
				})
				this.getNeighborhood()
			}
		})
	}

	getNeighborhood() {
		this.setState({ loading: true }, () => {
			neighborhood.query('#city = :city', { ':city': this.state.city }, { '#city': 'city' }).then(data => {
				if (data.status) {
					this.setState({
						loading: false,
						neighborhoods: data.data.Items.map(n => {
							return {
								val: n.uuid,
								text: n.name
							}
						})
					})
				} else {
					this.setState({ loading: false })
					Alert('Tenemos problemas de conexión', 'danger')
				}
			})
		})
	}

	registerUser(data) {
		return new Promise((resolve, reject) => {
			const pass = bcrypt.hashSync(data.password, 8)
			users
				.update(
					{
						name: data.name,
						password: pass,
						rol: 'ECA',
						eca: data.uuid,
						user: data.emailnew
					},
					{ user: data.email }
				)
				.then(() => {
					resolve()
				})
		})
	}

	updateUser(data) {
		return new Promise((resolve, reject) => {
			const pass = bcrypt.hashSync(data.password, 8)
			users
				.update(
					{
						name: data.name,
						password: pass,
						rol: 'ECA',
						eca: data.uuid
					},
					{ user: data.email }
				)
				.then(() => {
					resolve()
				})
		})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (
			data.get('name') !== '' &&
			data.get('city') !== '' &&
			data.get('neighborhood') !== '' &&
			data.get('phone') !== '' &&
			data.get('email') !== ''
		) {
			// avannzar sin cambiar contraseña
			if (emailRegex.test(data.get('email'))) {
				const newData = {
					name: data.get('name'),
					city: data.get('city'),
					neighborhood: data.get('neighborhood'),
					phone: data.get('phone'),
					email: data.get('email')
				}
				const dataCity = this.state.cities.filter(c => c.val === newData.city)[0]
				const dataNeighborhood = this.state.neighborhoods.filter(c => c.val === newData.neighborhood)[0]
				const wastesEca = this.state.wastesEca
				const status = this.state.status

				const elemail = data.get('email')
				this.setState({ loading: true }, async () => {
					// si cambia la info del usuario
					console.log('valida si cambió el user')
					if (newData.email !== this.state.user) {
						console.log('cambió el user')
						// Verificacion de usuario no registrado
						const userExist = await users.get({ user: newData.email })
						if (!('Item' in userExist.data)) {
							if (data.get('password').length > 7) {
								if (data.get('password') === data.get('password_confirmation')) {
									// Registro de usuario
									await this.registerUser({
										emailnew: newData.email,
										email: this.state.user,
										name: newData.name,
										password: data.get('password'),
										uuid: this.props.params.uuid
									})
								} else {
									Alert('Las contraseñas no coinciden', 'danger')
								}
							} else {
								Alert('La contraseña es demasiado corta', 'danger')
							}
						} else {
							this.setState({ loading: false }, () => {
								Alert('El usuario ya se encuentra registrado', 'danger')
							})
						}
					}
					// si hay contraseñas
					if (data.get('password').length > 0) {
						if (data.get('password').length > 7) {
							if (data.get('password') === data.get('password_confirmation')) {
								// actualizo la contraseña
								await this.updateUser({
									email: newData.email,
									name: newData.name,
									password: data.get('password'),
									uuid: this.props.params.uuid
								})
							} else {
								Alert('Las contraseñas no coinciden', 'danger')
							}
						} else {
							Alert('La contraseña es demasiado corta', 'danger')
						}
					}

					await ecas.update(
						{
							name: newData.name,
							city: {
								uuid: dataCity.val,
								name: dataCity.text
							},
							neighborhood: {
								uuid: dataNeighborhood.val,
								name: dataNeighborhood.text
							},
							neighborhood_uuid: dataNeighborhood.val,
							city_uuid: dataCity.val,
							phone: newData.phone,
							wastes: wastesEca,
							status,
							user: elemail
						},
						{ uuid: this.props.params.uuid }
					)

					this.setState({ loading: false }, () => {
						this.props.navigate('admin/settings/ecas')
						Alert('Eca actualizada')
					})
				})
			} else {
				Alert('Por favor ingresa un correo válido', 'danger')
			}
		} else {
			Alert('Ingresa la información requerida (*)', 'danger')
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/settings/ecas" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>E.C.A s</h2>
						<span>Editar</span>
					</div>
					<div className="btns">
						<NavLink to={`/admin/settings/gatherers/${this.props.params.uuid}`} className="btn">
							Recolectores
						</NavLink>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre de la E.C.A *" placeholder="Ingresa el nombre" name="name" value={this.state.data.name} />
							<Input
								type="select"
								title="Ciudad de la E.C.A *"
								options={this.state.cities}
								value={this.state.city}
								change={val => {
									this.setState({ city: val }, () => {
										this.getNeighborhood()
									})
								}}
								name="city"
							/>
							<Input
								type="select"
								title="Barrio de la E.C.A *"
								options={this.state.neighborhoods}
								value={this.state.neighborhood}
								change={val => this.setState({ neighborhood: val })}
								name="neighborhood"
							/>
							<Input type="text" title="Telefono de contacto *" name="phone" value={this.state.data.phone} />
						</div>
						<div className="column">
							<Input type="text" title="Usuario *" placeholder="Ingresa el usuario" name="email" value={this.state.data.user} />
							<Input type="password" title="Contraseña" placeholder="Ingresa la contraseña" name="password" />
							<Input type="password" title="Confirmar contraseña" placeholder="Confirma la contraseña" name="password_confirmation" />
							<Input
								type="checkbox"
								title="Estado (Inactiva / Activa)"
								name="status"
								active={this.state.status}
								change={val => this.setState({ status: val })}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<div className="title-page">
						<div className="title">
							<h2>Residuos que gestiona esta E.C.A</h2>
						</div>
					</div>
					{this.state.wastes.map(waste => (
						<Input
							key={waste.uuid}
							type="checkbox"
							title={waste.title}
							active={this.state.wastesEca.filter(w => w.uuid === waste.uuid).length > 0}
							change={val => {
								let wastesEca = this.state.wastesEca
								if (val) {
									wastesEca.push({
										uuid: waste.uuid,
										title: waste.title
									})
								} else {
									wastesEca = wastesEca.filter(w => w.uuid !== waste.uuid)
								}
								this.setState({ wastesEca })
							}}
						/>
					))}
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}

export default withParams(Page)
