import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import e from 'utiles/settings'
import { Alert } from 'utiles/functions'
import Input from 'componentes/input'
import DB from 'utiles/db'
const users = new DB('users')
const levels = new DB('levels')

class Show extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			user: {}
		}
	}

	async componentDidMount() {
		try {
			const result = await users.get({ user: this.props.params.user })
			if (result.status !== true) {
				console.error(result)
				this.setState({ loading: false })
				Alert('Error al cargar el usuario', 'danger')
				return
			}
			const user = result.data.Item
			if (user.points !== undefined && user.points !== null) {
				const allLevelsResult = await levels.query('#status = :status', { ':status': true }, { '#status': 'status' })
				if (allLevelsResult.status !== true) {
					console.error(allLevelsResult)
					this.setState({ loading: false })
					Alert('Error al cargar los niveles', 'danger')
					return
				}
				for (const i in allLevelsResult.data.Items) {
					if (user.points <= allLevelsResult.data.Items[i].points) {
						user.level = {
							...allLevelsResult.data.Items[i],
							i
						}
						break
					}
				}
			}
			this.setState({ user, loading: false })
		} catch (error) {
			console.error(error)
			this.setState({ loading: false })
			Alert('Error al cargar el usuario', 'danger')
		}
	}

	handleSubmit = async event => {
		event.preventDefault()
		try {
			const data = new FormData(event.currentTarget)
			if ([null, undefined, ''].includes(data.get('points'))) {
				Alert('Los puntos no pueden estar vacios', 'danger')
				return
			}
			this.setState({ loading: true })
			const points = Number(data.get('points')) // si antes era un texto, ahora es un número
			const updatedPointsAt = new Date().getTime()
			const result = await users.update({ points, updatedPointsAt }, { user: this.props.params.user })
			if (result.status !== true) {
				console.error(result)
				this.setState({ loading: false })
				Alert('Error al actualizar los puntos', 'danger')
				return
			}
			this.setState({ loading: false })
			Alert('Puntos del usuario actualizados correctamente', 'success')
			this.props.navigate('admin/users')
		} catch (error) {
			console.error(error)
			this.setState({ loading: false })
			Alert('Error al actualizar los puntos', 'danger')
		}
	}

	getRole(isAdmin, isCollector) {
		if (isAdmin) {
			return <strong>Administrador</strong>
		}
		if (isCollector) {
			return <strong>Recolector</strong>
		}
		return 'Usuario'
	}

	render() {
		return (
			<div>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/users" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Detalles del usuario</h2>
						{/* <span>{this.props.params.user}</span> */}
					</div>
				</div>
				<div className="columns">
					<div className="column" style={{ width: '30%' }}>
						<div className="card">
							<div className="container-avatar">
								{this.state.user.avatar ? (
									<img src={e.urlBucket + this.state.user.avatar} alt="Avatar" width={35} height={35} />
								) : (
									<img src={require('img/user.png')} alt="Avatar" width={35} height={35} />
								)}
							</div>
							<div className="legend">
								<strong>Nombre: </strong>
								<span>{this.state.user.name || this.state.user.nickname}</span>
							</div>
							<div className="legend">
								<strong>Tipo de documento: </strong>
								<span>{this.state.user.typeDoc || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Número de documento: </strong>
								<span>{this.state.user.doc || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Corre: </strong>
								<span>{this.state.user.email || this.state.user.user}</span>
							</div>
							<div className="legend">
								<strong>Teléfono: </strong>
								<span>{this.state.user.phone || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Dirección: </strong>
								<span>{this.state.user.address || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Ciudad: </strong>
								<span>{this.state.user.city?.name || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Barrio: </strong>
								<span>{this.state.user.neighborhood?.name || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>E.C.A: </strong>
								<span>{this.state.user.eca?.name || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Código de referidos: </strong>
								<span>{this.state.user.code || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Código referente: </strong>
								<span>{this.state.user.codeRefer || 'No definido'}</span>
							</div>
							<div className="legend">
								<strong>Rol: </strong>
								<span>{this.getRole(this.state.user.is_admin, this.state.user.is_collector)}</span>
							</div>
							<div className="legend">
								<strong>Puntos: </strong>
								<span>{this.state.user.points || 0}</span>
							</div>
						</div>
					</div>
					<div className="column" style={{ width: '68%' }}>
						<div className="card">
							{this.state.user.level && (
								<div className="container-level-info">
									<h2>Nivel del usuario</h2>
									<div className="container-avatar">
										<img src={e.urlBucket + this.state.user.level.icono} alt="Avatar" width={35} height={35} />
									</div>
									<strong>{this.state.user.level.name}</strong>
									<strong>
										Nivel {this.state.user.level.i} | puntos {this.state.user.points || 0}
									</strong>
								</div>
							)}
							<div className="divider"></div>
							<form action="#" onSubmit={this.handleSubmit}>
								<Input
									type="number"
									title="Asignar puntos al usuario (Esta acción sobre escribe los puntos, NO los suma)"
									placeholder="0"
									value={this.state.user.points}
									name="points"
								/>
								<div className="divider"></div>
								<button className="btn" type="submit">
									Actualizar puntos del usuario
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default withParams(Show)
