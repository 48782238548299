import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import { Alert, uploadImage, deleteImage } from 'utiles/functions'
import Modal from 'componentes/modal'
import DB from 'utiles/db'
import e from 'utiles/settings'
import { v4 as uuidv4 } from 'uuid'
const levels = new DB('levels')
let oldPremios = {}

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			icono: '',
			iconoPremio: '',
			modalPremio: false,
			premioEdit: {},
			modalEditPremio: false,
			// datos del nivel
			name: '',
			status: true,
			points: '',
			description: '',
			oldIcono: '',
			premios: [],
			oldPremios: []
		}
	}

	componentDidMount() {
		levels.get({ uuid: this.props.params.uuid }).then(data => {
			if (data.status) {
				this.setState({
					loading: false,
					name: data.data.Item.name,
					status: data.data.Item.status,
					points: data.data.Item.points,
					description: data.data.Item.description,
					oldIcono: data.data.Item.icono,
					premios: data.data.Item.premios
				})
				oldPremios = data.data.Item.premios
			} else {
				console.log(data)
				this.setState({ loading: false })
				Alert('¡Ooops!, tenemos un problemas para crear el nivel, intenta de nuevo', 'danger')
			}
		})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)

		if (
			data.get('name') !== '' &&
			data.get('points') !== '' &&
			Object.keys(this.state.premios).length > 0 &&
			(this.state.icono !== '' || this.state.oldIcono !== '')
		) {
			this.setState({ loading: true }, async () => {
				try {
					const dataLevel = {
						name: data.get('name'),
						points: data.get('points'),
						description: data.get('description'),
						status: this.state.status,
						icono: this.state.oldIcono
					}

					// elimino la imagen anterior
					// -------------------------
					if (this.state.icono !== '') {
						await deleteImage(this.state.oldIcono)
						const url = await uploadImage(this.state.icono, 'level_')
						dataLevel.icono = url
					}

					// Eliminar imagenes de premios eliminados
					// -------------------------
					const allPremios = this.state.premios
					const toDeletePremios = {}
					for (const uuid of Object.keys(oldPremios)) {
						if (this.state.premios[uuid] === undefined) {
							toDeletePremios[uuid] = oldPremios[uuid]
						}
					}
					for (const uuid of Object.keys(toDeletePremios)) {
						await deleteImage(toDeletePremios[uuid].icono)
					}

					// Subir imagenes de los nuevos premios
					// -------------------------
					const newPremios = {}
					for (const uuid of Object.keys(this.state.premios)) {
						if (oldPremios[uuid] === undefined) {
							newPremios[uuid] = this.state.premios[uuid]
						}
					}
					for (const uuid of Object.keys(newPremios)) {
						const url = await uploadImage(newPremios[uuid].icono, 'premio_')
						allPremios[uuid].icono = url
					}

					// Actulizar imagenes de los premios actualizados
					// --------------------------
					for (const uuid of Object.keys(allPremios)) {
						if (allPremios[uuid].icono.indexOf('data:image') > -1) {
							const url = await uploadImage(allPremios[uuid].icono, 'premio_')
							allPremios[uuid].icono = url
						}
					}

					// Actualizacion del nivel
					// -------------------------
					dataLevel.premios = allPremios
					levels
						.update(dataLevel, { uuid: this.props.params.uuid })
						.then(result => {
							if (result.status) {
								this.setState({ loading: false })
								this.props.navigate('admin/levels')
								Alert('Nivel registrado correctamente.')
							} else {
								console.log(result)
								this.setState({ loading: false })
								Alert('¡Ooops!, tenemos un problemas para actualizar el nivel, intenta de nuevo', 'danger')
							}
						})
						.catch(err => {
							console.log(err)
							this.setState({ loading: false })
							Alert('¡Ooops!, tenemos un problemas para actualizar el nivel, intenta de nuevo', 'danger')
						})
				} catch (err) {
					console.log(err)
					this.setState({ loading: false })
					Alert('¡Ooops!, tenemos un problemas para crear actualizar el nivel, intenta de nuevo', 'danger')
				}
			})
		} else {
			Alert('Ingresa la información requerida ( * )', 'danger')
		}
	}

	addPremio(e) {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (data.get('name') !== '' && this.state.iconoPremio !== '') {
			const premios = this.state.premios
			premios[uuidv4()] = {
				name: data.get('name'),
				icono: this.state.iconoPremio
			}
			this.setState({
				premios,
				iconoPremio: '',
				modalPremio: false
			})
		} else {
			Alert('Ingresa la información requerida ( * )', 'danger')
		}
	}

	modalAddPremio() {
		return (
			<Modal active={this.state.modalPremio}>
				<form action="#" onSubmit={e => this.addPremio(e)}>
					<div className="title-page">
						<div className="title">
							<h2>Agregar premio al nivel</h2>
						</div>
					</div>
					<Input type="image" title="Icono *" img={this.state.iconoPremio} change={image => this.setState({ iconoPremio: image })} />
					<Input type="text" title="Nombre del premio *" placeholder="Ingresa el nombre" name="name" />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalPremio: false, iconoPremio: '' })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	updatePremio(e) {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (data.get('name') !== '' && this.state.iconoPremio !== '') {
			const premios = this.state.premios
			premios[this.state.premioEdit.uuid] = {
				name: data.get('name'),
				icono: this.state.iconoPremio
			}
			this.setState({
				premios,
				iconoPremio: '',
				premioEdit: {},
				modalEditPremio: false
			})
		} else {
			Alert('Ingresa la información requerida ( * )', 'danger')
		}
	}

	modalEditPremio() {
		return (
			<Modal active={this.state.modalEditPremio}>
				<form action="#" onSubmit={e => this.updatePremio(e)}>
					<div className="title-page">
						<div className="title">
							<h2>Editar premio</h2>
						</div>
					</div>
					<Input
						type="image"
						title="Icono *"
						img={this.state.iconoPremio}
						simple={this.state.iconoPremio.indexOf('data:image') > -1}
						change={image => this.setState({ iconoPremio: image })}
					/>
					<Input type="text" title="Nombre del premio *" value={this.state.premioEdit.name} placeholder="Ingresa el nombre" name="name" />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalEditPremio: false, iconoPremio: '' })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.modalAddPremio()}
				{this.modalEditPremio()}
				<form action="#" onSubmit={this.handleSubmit}>
					<Loader active={this.state.loading} message="Cargando..." />
					<div className="title-page">
						<div className="title">
							<NavLink to="/admin/levels" className="back">
								<img src={require('img/back.svg').default} width={25} height={25} />
							</NavLink>
							<h2>Niveles</h2>
							<span>Editar nivel</span>
						</div>
						<div className="btns">
							<button className="btn" type="submit">
								Guardar
							</button>
						</div>
					</div>
					<div className="card">
						<div className="columns">
							<div className="column">
								<Input type="text" title="Nombre del nivel *" value={this.state.name} placeholder="Ingresa el nombre del nivel" name="name" />
								<Input
									type="number"
									title="Puntos del nivel *"
									value={this.state.points}
									placeholder="Cantidad de puntos para alcanzar el nivel."
									name="points"
								/>
								<Input type="textarea" title="Descripcion del nivel" value={this.state.description} placeholder="..." name="description" />
							</div>
							<div className="column">
								<Input type="image" title="Icono *" img={this.state.oldIcono} change={image => this.setState({ icono: image })} />
								<Input type="checkbox" title="Estado del nivel" name="status" change={v => this.setState({ status: v })} active={this.state.status} />
							</div>
						</div>
						<div className="divider"></div>
						<div className="title-page">
							<div className="title">
								<h2>Premios del nivel *</h2>
							</div>
							{Object.keys(this.state.premios).length < 5 && (
								<div className="btns">
									<a
										className="btn"
										href="#"
										onClick={e => {
											e.preventDefault()
											this.setState({ modalPremio: true })
										}}>
										Agregar premio
									</a>
								</div>
							)}
						</div>
						{Object.keys(this.state.premios).length > 0 && (
							<>
								<div className="divider"></div>
								<div className="container-premios">
									{Object.keys(this.state.premios).map(uuid => (
										<div className="premio-item" key={uuid}>
											<div className="container-img">
												{this.state.premios[uuid].icono.indexOf('data:image') > -1 ? (
													<img src={this.state.premios[uuid].icono} />
												) : (
													<img src={e.urlBucket + this.state.premios[uuid].icono} />
												)}
											</div>
											<span>{this.state.premios[uuid].name}</span>
											<div className="btns">
												<a
													href="#"
													className="btn"
													onClick={e => {
														e.preventDefault()
														const keys = Object.keys(this.state.premios).filter(u => u !== uuid)
														const premios = {}
														for (const u of keys) {
															premios[u] = this.state.premios[u]
														}
														this.setState({ premios })
													}}>
													Eliminar
												</a>
												<a
													href="#"
													className="btn"
													onClick={e => {
														e.preventDefault()
														this.setState({
															modalEditPremio: true,
															iconoPremio: this.state.premios[uuid].icono,
															premioEdit: {
																uuid,
																...this.state.premios[uuid]
															}
														})
													}}>
													Editar
												</a>
											</div>
										</div>
									))}
								</div>
							</>
						)}
						<div className="divider"></div>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</>
		)
	}
}
export default withParams(Page)
