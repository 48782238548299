import { decode } from 'base64-arraybuffer'
import { S3 } from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid'
import e from './settings'

export const Alert = (msg, type = 'success', duration = 7000) => {
	const p = document.createElement('p')
	const btn = document.createElement('a')
	const div = document.createElement('div')
	p.innerHTML = msg
	btn.setAttribute('href', '#')
	btn.setAttribute('class', 'icon-close')
	btn.innerHTML = 'x'
	btn.addEventListener('click', function (e) {
		this.parentNode.remove()
	})
	div.setAttribute('class', type)
	div.appendChild(p)
	div.appendChild(btn)
	document.getElementById('notifications').appendChild(div)
	setTimeout(() => {
		div.animate([{ opacity: 1 }, { opacity: 0 }], { duration: 300 })
		setTimeout(() => div.remove(), 250)
	}, duration)
}

export const Price = value => {
	const price = Number(value)
	return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'COP', maximumFractionDigits: 0, maximumSignificantDigits: 10 }).format(price)
}

export const Levenshtein = (a, b) => {
	function _min(d0, d1, d2, bx, ay) {
		return d0 < d1 || d2 < d1 ? (d0 > d2 ? d2 + 1 : d0 + 1) : bx === ay ? d1 : d1 + 1
	}
	if (a === b) {
		return 0
	}

	if (a.length > b.length) {
		const tmp = a
		a = b
		b = tmp
	}
	let la = a.length
	let lb = b.length
	while (la > 0 && a.charCodeAt(la - 1) === b.charCodeAt(lb - 1)) {
		la--
		lb--
	}
	let offset = 0
	while (offset < la && a.charCodeAt(offset) === b.charCodeAt(offset)) {
		offset++
	}
	la -= offset
	lb -= offset
	if (la === 0 || lb < 3) {
		return lb
	}
	let x = 0
	let y
	let d0
	let d1
	let d2
	let d3
	let dd
	let dy
	let ay
	let bx0
	let bx1
	let bx2
	let bx3
	const vector = []
	for (y = 0; y < la; y++) {
		vector.push(y + 1)
		vector.push(a.charCodeAt(offset + y))
	}
	const len = vector.length - 1
	for (; x < lb - 3; ) {
		bx0 = b.charCodeAt(offset + (d0 = x))
		bx1 = b.charCodeAt(offset + (d1 = x + 1))
		bx2 = b.charCodeAt(offset + (d2 = x + 2))
		bx3 = b.charCodeAt(offset + (d3 = x + 3))
		dd = x += 4
		for (y = 0; y < len; y += 2) {
			dy = vector[y]
			ay = vector[y + 1]
			d0 = _min(dy, d0, d1, bx0, ay)
			d1 = _min(d0, d1, d2, bx1, ay)
			d2 = _min(d1, d2, d3, bx2, ay)
			dd = _min(d2, d3, dd, bx3, ay)
			vector[y] = dd
			d3 = d2
			d2 = d1
			d1 = d0
			d0 = dy
		}
	}
	for (; x < lb; ) {
		bx0 = b.charCodeAt(offset + (d0 = x))
		dd = ++x
		for (y = 0; y < len; y += 2) {
			dy = vector[y]
			vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1])
			d0 = dy
		}
	}
	return dd
}

export const uploadImage = (image, prefix = '') => {
	return new Promise((resolve, reject) => {
		const fileName = prefix + uuidv4() + '.jpg'
		const contentDeposition = 'inline;filename="' + fileName + '"'
		const arrayBuffer = decode(image.split('base64,')[1])
		const s3bucket = new S3({
			region: e.region,
			accessKeyId: e.accessKeyId,
			secretAccessKey: e.secretAccessKey,
			Bucket: e.bucket,
			signatureVersion: 'v4'
		})
		s3bucket.createBucket(() => {
			const params = {
				Bucket: e.bucket,
				Key: fileName,
				Body: arrayBuffer,
				ContentDisposition: contentDeposition,
				ContentType: 'image/jpeg'
			}
			s3bucket.upload(params, (err, data) => {
				if (err) {
					reject(err)
				}
				resolve(fileName)
			})
		})
	})
}

export const deleteImage = image => {
	return new Promise((resolve, reject) => {
		const s3bucket = new S3({
			region: e.region,
			accessKeyId: e.accessKeyId,
			secretAccessKey: e.secretAccessKey,
			Bucket: e.bucket,
			signatureVersion: 'v4'
		})
		s3bucket.deleteObject({ Bucket: e.bucket, Key: image }, (err, data) => {
			if (err) {
				reject(err)
			}
			resolve(data)
		})
	})
}

export const deleteFile = file => {
	return new Promise((resolve, reject) => {
		const s3bucket = new S3({
			region: e.region,
			accessKeyId: e.accessKeyId,
			secretAccessKey: e.secretAccessKey,
			Bucket: e.bucket,
			signatureVersion: 'v4'
		})
		s3bucket.deleteObject({ Bucket: e.bucket, Key: file }, (err, data) => {
			if (err) {
				reject(err)
			}
			resolve(data)
		})
	})
}

export const Confirm = (msg, callback, callback2 = null, no = 'Cancelar', si = 'Aceptar') => {
	const p = document.createElement('p')
	const btn = document.createElement('a')
	const btn2 = document.createElement('a')
	const padre = document.createElement('div')
	const box = document.createElement('div')
	const botones = document.createElement('div')
	p.setAttribute('class', 'msg-confirm')
	p.innerHTML = msg

	// boton de aceptar
	btn.innerHTML = si
	btn.setAttribute('href', '#')
	btn.addEventListener('click', function (e) {
		this.parentNode.parentNode.parentNode.remove()
		callback()
	})

	// boton de cancelar
	btn2.innerHTML = no
	btn2.setAttribute('href', '#')
	btn2.addEventListener('click', function (e) {
		this.parentNode.parentNode.parentNode.remove()
		if (callback2 !== null) {
			callback2()
		}
	})

	// div de botones
	botones.setAttribute('class', 'botones-confirm')
	botones.appendChild(btn2)
	botones.appendChild(btn)

	// agregar botones y texto
	box.setAttribute('class', 'body-modal')
	box.appendChild(p)
	box.appendChild(botones)

	padre.setAttribute('class', 'modal-app')
	padre.appendChild(box)

	document.getElementsByTagName('body')[0].appendChild(padre)
}

export const hoy = (time = null, withHour = false) => {
	const h = time === null ? new Date() : new Date(Number(time))
	const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
	const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
	const getHours = () => {
		const h24 = Number(h.getHours())
		let pm = false
		let newH = 0
		if (h24 > 12) {
			newH = h24 - 12
			pm = true
		}
		return ' a las ' + newH + ':' + h.getMinutes() + (pm ? ' pm' : ' am')
	}
	return dias[h.getDay()] + ' ' + h.getDate() + ' de ' + meses[h.getMonth()] + (withHour ? getHours() : '')
}

export const parseDate = time => {
	time = Number(time)
	if (isNaN(time)) {
		return 'No definido'
	}
	const h = new Date(Number(time))
	return h.getDate() + '/' + (h.getMonth() + 1) + '/' + h.getFullYear()
}

export const parseDateStringToNumber = dateString => {
	// Esto recibe un formato como este: 14/02/2024 - 10:48 a.m
	// y lo convierte a un formato como este: YYYY-MM-DDTHH:mm:ss.sssZ
	const parts = dateString.split(' - ')
	const date = parts[0].split('/')
	const time = parts[1].split(' ')
	const hours = time[0].split(':')
	const hour = Number(hours[0])
	const minutes = Number(hours[1])
	const ampm = time[1]
	let h = hour
	if (ampm === 'p.m') {
		h = hour + 12
	}
	return new Date(date[2], date[1] - 1, date[0], h, minutes)
}

export const postNotification = (to, title, body) => {
	const url = 'https://fcm.googleapis.com/fcm/send'
	const notify = {
		to,
		notification: {
			title,
			body
		}
	}
	return new Promise((resolve, reject) => {
		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization:
					'key=AAAAQHTv6Ho:APA91bHVPqFl3UWKk9BW6uL3zASgKpwYn58nshkL3-ABZC62FBKoZhdb1q7xTpoFmYsUJO7nEP4E6keJYI-US5eFMbRMI-v2LJNMZGAZSImD8jJTNEG4n_yzQQGlPYS7yLttoUUY_C7U'
			},
			body: JSON.stringify(notify)
		})
			.then(r => r.json())
			.then(data => {
				resolve(data)
			})
	})
}
