import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'
import Input from 'componentes/input'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import { Alert } from 'utiles/functions'
const settings = new DB('settings')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: [],
			can_login: false,
			can_refer: false,
			can_report: false,
			can_collector: false,
			can_ecommerce: false,
			can_learning: false,
			can_banner: false,
			can_levels: false
		}
	}

	componentDidMount() {
		settings.find({ uuid: '34e8921g-8122-420f-c107-8g7gc26b7d7f' }).then(data => {
			if (data.status) {
				this.setState({
					data: data.data.Item,
					can_login: data.data.Item.can_login,
					can_refer: data.data.Item.can_refer,
					can_report: data.data.Item.can_report,
					can_collector: data.data.Item.can_collector,
					can_ecommerce: data.data.Item.can_ecommerce,
					can_learning: data.data.Item.can_learning,
					can_banner: data.data.Item.can_banner,
					can_levels: data.data.Item.can_levels,
					loading: false
				})
			}
		})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		this.setState({ loading: true }, () => {
			settings
				.update(
					{
						web: data.get('web'),
						help: data.get('help'),
						instagram: data.get('instagram'),
						facebook: data.get('facebook'),
						whatsapp: data.get('whatsapp'),
						linkedin: data.get('linkedin'),
						youtube: data.get('youtube'),
						tiktok: data.get('tiktok'),
						pointsRefer: data.get('pointsRefer'),
						can_login: this.state.can_login,
						can_refer: this.state.can_refer,
						can_report: this.state.can_report,
						can_collector: this.state.can_collector,
						can_ecommerce: this.state.can_ecommerce,
						can_learning: this.state.can_learning,
						can_banner: this.state.can_banner,
						can_levels: this.state.can_levels
					},
					{ uuid: '34e8921g-8122-420f-c107-8g7gc26b7d7f' }
				)
				.then(r => {
					if (r.status) {
						this.setState({ loading: false })
						Alert('Configuración actualizada con exito')
					} else {
						this.setState({ loading: false }, () => {
							Alert('Tenemos problemas de conexión, intentalo de nuevo', 'danger')
						})
					}
				})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<a href="#" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</a>
						<h2>Configuración</h2>
						<span>Global</span>
					</div>
				</div>
				<div className="grid" style={{ marginBottom: 20 }}>
					<div className="card">
						<div className="title-page">
							<div className="title">
								<h2>Niveles</h2>
							</div>
							<div className="btns">
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/admin/levels')
									}}
									className="btn">
									Gestionar
								</a>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="title-page">
							<div className="title">
								<h2>Tipos de residuos</h2>
							</div>
							<div className="btns">
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/admin/typewaste')
									}}
									className="btn">
									Gestionar
								</a>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="title-page">
							<div className="title">
								<h2>Tipos de reportes</h2>
							</div>
							<div className="btns">
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/admin/typealerts')
									}}
									className="btn">
									Gestionar
								</a>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="title-page">
							<div className="title">
								<h2>Ubicaciones</h2>
							</div>
							<div className="btns">
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/admin/settings/cities')
									}}
									className="btn">
									Gestionar
								</a>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="title-page">
							<div className="title">
								<h2>E.C.A s</h2>
							</div>
							<div className="btns">
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/admin/settings/ecas')
									}}
									className="btn">
									Gestionar
								</a>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="title-page">
							<div className="title">
								<h2>Banner de imagenes</h2>
							</div>
							<div className="btns">
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/admin/banners')
									}}
									className="btn">
									Gestionar
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="card">
					<form action="#" onSubmit={this.handleSubmit}>
						<div className="columns">
							<div className="column">
								<Input type="text" title="URL sitio web" placeholder="https://" name="web" value={this.state.data.web} />
								<Input type="text" title="URL de ayuda" placeholder="https://" name="help" value={this.state.data.help} />
								<Input type="text" title="URL Instagram" placeholder="https://" name="instagram" value={this.state.data.instagram} />
								<Input type="text" title="URL Faceboock" placeholder="https://" name="facebook" value={this.state.data.facebook} />
								<Input type="text" title="URL Whatsapp" placeholder="https://" name="whatsapp" value={this.state.data.whatsapp} />
								<Input type="text" title="URL Linkedin" placeholder="https://" name="linkedin" value={this.state.data.linkedin} />
								<Input type="text" title="URL Youtube" placeholder="https://" name="youtube" value={this.state.data.youtube} />
								<Input type="text" title="URL Tiktok" placeholder="https://" name="tiktok" value={this.state.data.tiktok} />
							</div>
							<div className="column">
								<Input type="number" title="Puntos por referido" placeholder="..." name="pointsRefer" value={this.state.data.pointsRefer} />
								<Input
									type="checkbox"
									title="Permitir codigo de referidos"
									name="can_refer"
									change={v => this.setState({ can_refer: v })}
									active={this.state.can_refer}
								/>
								<Input
									type="checkbox"
									title="Permitir login con redes sociales"
									name="can_login"
									change={v => this.setState({ can_login: v })}
									active={this.state.can_login}
								/>
								<Input
									type="checkbox"
									title="Permitir reportes"
									name="can_report"
									change={v => this.setState({ can_report: v })}
									active={this.state.can_report}
								/>
								<Input
									type="checkbox"
									title="Permitir solicitudes de recolección"
									name="can_collector"
									change={v => this.setState({ can_collector: v })}
									active={this.state.can_collector}
								/>
								<Input
									type="checkbox"
									title="Permitir e-commerce"
									name="can_ecommerce"
									change={v => this.setState({ can_ecommerce: v })}
									active={this.state.can_ecommerce}
								/>
								<Input
									type="checkbox"
									title="Permitir e-learning"
									name="can_learning"
									change={v => this.setState({ can_learning: v })}
									active={this.state.can_learning}
								/>
								<Input
									type="checkbox"
									title="Permitir banner principal"
									name="can_banner"
									change={v => this.setState({ can_banner: v })}
									active={this.state.can_banner}
								/>
								<Input
									type="checkbox"
									title="Permitir niveles"
									name="can_levels"
									change={v => this.setState({ can_levels: v })}
									active={this.state.can_levels}
								/>
							</div>
						</div>
						<br />
						<button className="btn" type="submit">
							Guardar
						</button>
					</form>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
