import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
import { v4 as uuidv4 } from 'uuid'
const cities = new DB('cities')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '') {
			this.setState({ loading: true }, () => {
				cities
					.create({
						uuid: uuidv4(),
						name: data.get('name')
					})
					.then(r => {
						if (r.status) {
							this.props.navigate('/admin/settings/cities')
							Alert('Ciudad registrada con exito')
						} else {
							this.setState({ loading: false }, () => {
								Alert('Tenemos problemas de conexión, intentalo de nuevo', 'danger')
							})
						}
					})
			})
		} else {
			Alert('Ingresa la información requerida (*)', 'danger')
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/settings/cities" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Ciudades</h2>
						<span>Nueva</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
							<Input type="text" title="Nombre de la ciudad *" placeholder="Ingresa el nombre" name="name" />
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
