import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import AnimateScreen from 'componentes/animateScreen'
import { Alert, Levenshtein, Confirm, deleteFile } from 'utiles/functions'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import Input from 'componentes/input'
import e from 'utiles/settings'
let alertas = []
const reports = new DB('reports')
const typeReport = new DB('type_report')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			reportes: [],
			all: 0,
			news: 0,
			evalu: 0,
			inter: 0,
			close: 0,
			optionsTipo: [],
			fuser: '',
			ftipo: '',
			festado: ''
		}
	}

	componentDidMount() {
		this.init()
	}

	init = async () => {
		const types = await typeReport.getAll()
		if (types.status) {
			const optionsTipo = types.data.Items.map(type => {
				return { val: type.name, text: type.name }
			})
			optionsTipo.unshift({
				val: '',
				text: 'Selecciona un tipo'
			})
			this.setState({ optionsTipo })
		}
		const result = await reports.getAll()
		if (result.status) {
			const all = result.data.Items.length
			let news = 0
			let evalu = 0
			let inter = 0
			let close = 0
			result.data.Items.map(item => {
				if (item.evaluacion === undefined && item.intervenciones === undefined && item.cierre === undefined) {
					item.status = 'Nuevo'
					news++
				} else if (item.evaluacion !== undefined && item.intervenciones === undefined && item.cierre === undefined) {
					item.status = 'En evaluación'
					evalu++
				} else if (item.evaluacion !== undefined && item.intervenciones !== undefined && item.cierre === undefined) {
					item.status = 'En intervenciones'
					inter++
				} else if (item.evaluacion !== undefined && item.intervenciones !== undefined && item.cierre !== undefined) {
					item.status = 'Cerrado'
					close++
				} else {
					item.status = 'Nuevo'
				}
				return item
			})
			alertas = result.data.Items
			this.setState({
				loading: false,
				reportes: result.data.Items,
				all,
				news,
				evalu,
				inter,
				close
			})
		} else {
			this.setState({ loading: false }, () => {
				Alert('Ooops tenemos problemas para establecer conexión.', 'danger')
			})
		}
	}

	deleteReport(item) {
		Confirm('¿Realmente deseas eliminar este reporte?', async () => {
			this.setState({ loading: true })
			await deleteFile(item.name_file)
			await reports.delete({ uuid: item.uuid })
			Alert('Reporte eliminado')
			this.setState({ loading: false }, () => {
				this.init()
			})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<h2>Reportes</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/admin/typealerts" className="btn">
							Tipos de reportes
						</NavLink>
					</div>
				</div>
				<div className="grid g5" style={{ marginBottom: 20 }}>
					<div className="card">
						<div className="item-widget">
							<span>Todos</span>
							<strong>{this.state.all}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Nuevos</span>
							<strong>{this.state.news}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>En evaluación</span>
							<strong>{this.state.evalu}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>En intervensión</span>
							<strong>{this.state.inter}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Cerrados</span>
							<strong>{this.state.close}</strong>
						</div>
					</div>
				</div>
				<div className="card">
					<p>Filtros</p>
					<div className="container-search">
						<div className="search">
							<input
								type="text"
								placeholder="Usuarios"
								onChange={event => {
									const val = event.target.value
									if (val.length > 2) {
										const alertash = this.state.reportes.filter(alerta => {
											const name = alerta.user.nickname.toLowerCase().split(' ')
											for (const palabra of name) {
												if (Levenshtein(palabra, val.toLowerCase()) < 3 || palabra.indexOf(val.toLowerCase()) > -1) {
													return true
												}
											}
											return false
										})
										this.setState({ reportes: alertash })
									} else {
										this.setState({ reportes: alertas })
									}
									this.setState({ fuser: val })
								}}
								value={this.state.fuser}
							/>
							<Input
								type="select"
								options={this.state.optionsTipo}
								change={val => {
									const alertash = this.state.reportes.filter(alerta => alerta.typeReport?.name === val)
									this.setState({ reportes: alertash, ftipo: val })
								}}
								value={this.state.ftipo}
							/>
							<Input
								type="select"
								options={[
									{
										val: '',
										text: 'Selecciona un estado'
									},
									{
										val: 'Nuevo',
										text: 'Nuevo'
									},
									{
										val: 'En evaluación',
										text: 'En evaluación'
									},
									{
										val: 'Cerrado',
										text: 'Cerrado'
									}
								]}
								change={val => {
									const alertash = this.state.reportes.filter(alerta => alerta.status === val)
									this.setState({ reportes: alertash, festado: val })
								}}
								value={this.state.festado}
							/>
							<button
								onClick={() => {
									this.setState({ reportes: alertas, fuser: '', festado: '', ftipo: '' })
								}}>
								Borrar
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Tipo de alerta</th>
								<th>Usuario</th>
								<th>Fecha</th>
								<th>Hora</th>
								<th>Estado</th>
								<th>Tipo</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.reportes.map(alerta => (
								<tr key={alerta.uuid}>
									{/* pedir a rafa que guarde el type en los reportes con la tabla type_report */}
									<td style={{ maxWidth: '200px' }}>{'typeReport' in alerta ? alerta.typeReport.name : 'Sin especificar'}</td>
									<td>{alerta.user.nickname}</td>
									<td>{alerta.date.split(' - ')[0]}</td>
									<td>{alerta.date.split(' - ')[1]}</td>
									<td>
										<span className={`status ${alerta.status === 'Cerrado' ? 'Activo' : 'Inactivo'}`}>{alerta.status}</span>
									</td>
									<td>{alerta.file_type === 'imagen' ? 'Imagen' : 'Video'}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('admin/alerts/edit/' + alerta.uuid)
											}}
											className="btn-edit">
											Gestionar
										</a>
										<a href={e.urlBucket + alerta.name_file} target="_blank" className="btn-edit" rel="noreferrer">
											Ver evidencia
										</a>
										<a
											href="#"
											className="btn-delete"
											onClick={event => {
												event.preventDefault()
												this.deleteReport(alerta)
											}}>
											Eliminar reporte
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
