import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Modal from 'componentes/modal'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import { Alert, postNotification, Confirm } from 'utiles/functions'
import e from 'utiles/settings'
import DB from 'utiles/db'
const reports = new DB('reports')
const typeReport = new DB('type_report')
const users = new DB('users')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			reporte: {},
			modal: false,
			options: [
				{ val: '', text: 'Seleccionar...' },
				{ val: 'Tu reporte esta en evaluación', text: 'En evaluación' },
				{ val: 'Se realizarón las intervenciones', text: 'En intervenciones' },
				{ val: 'Cierre del caso', text: 'Cerrado' }
			]
		}
	}

	componentDidMount() {
		reports
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				if (data.status) {
					if (data.data.Item.evaluacion === undefined && data.data.Item.intervenciones === undefined && data.data.Item.cierre === undefined) {
						data.data.Item.status = 'Nuevo'
					} else if (data.data.Item.evaluacion !== undefined && data.data.Item.intervenciones === undefined && data.data.Item.cierre === undefined) {
						data.data.Item.status = 'En evaluación'
					} else if (data.data.Item.evaluacion !== undefined && data.data.Item.intervenciones !== undefined && data.data.Item.cierre === undefined) {
						data.data.Item.status = 'En intervenciones'
					} else if (data.data.Item.evaluacion !== undefined && data.data.Item.intervenciones !== undefined && data.data.Item.cierre !== undefined) {
						data.data.Item.status = 'Cerrado'
					} else {
						data.data.Item.status = 'Nuevo'
					}
					this.setState({ loading: false, reporte: data.data.Item })
				} else {
					console.log(data)
					Alert('¡Ooops! tenemos problemas de conexión')
					this.setState({ loading: false })
				}
			})
			.catch(err => {
				console.log(err)
				Alert('¡Ooops! tenemos problemas de conexión')
				this.setState({ loading: false })
			})
	}

	printReport() {
		window.print()
	}

	updateReport(e) {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (data.get('text') !== '' && data.get('status') !== '') {
			this.setState({ loading: true, modal: false }, async () => {
				const dataStatus = {
					title: data.get('status'),
					content: data.get('text')
				}
				let expresion = ''
				if (dataStatus.title === 'Tu reporte esta en evaluación') {
					expresion = 'SET evaluacion = :data'
				}
				if (dataStatus.title === 'Se realizarón las intervenciones') {
					expresion = 'SET intervenciones = :data'
				}
				if (dataStatus.title === 'Cierre del caso') {
					expresion = 'SET cierre = :data'
				}
				await reports.oldUpdate({
					Key: { uuid: this.props.params.uuid },
					UpdateExpression: expresion,
					ExpressionAttributeValues: { ':data': dataStatus }
				})

				const exist = await users.get({ user: this.state.reporte.user.email })
				if (exist.status) {
					if ('token' in exist.data.Item) {
						postNotification(exist.data.Item.token, 'Tu reporte ha cambiado de estado', dataStatus.title)
					}
				}
				this.setState({ loading: false }, () => {
					Alert('Reporte actualizado')
					this.props.navigate('admin/alerts')
				})
			})
		} else {
			Alert('Ingresa la información requerida ( * )', 'danger')
		}
	}

	calculatePoints() {
		Confirm('¿Realmente deseas asignar los puntos a este usuario?', async () => {
			if ('typeReport' in this.state.reporte) {
				this.setState({ loading: true })
				const type = (await typeReport.get({ uuid: this.state.reporte.typeReport.uuid })).data.Item
				const user = (await users.get({ user: this.state.reporte.user.email })).data.Item
				let points = user.points || 0
				points = Number(points) + Number(type.points)
				await users.update({ points, updatedPointsAt: new Date().getTime() }, { user: user.email })
				await reports.update({ withPoints: type.points }, { uuid: this.props.params.uuid })
				if ('token' in user) {
					postNotification(user.token, '¡Genial, ganaste ' + type.points + ' puntos!', 'Has ganado ' + type.points + ' puntos por tu reporte')
				}
				this.setState({ loading: false }, () => {
					Alert('Puntos asignados al usuario')
					this.props.navigate('admin/alerts')
				})
			}
		})
	}

	modalUpdate() {
		return (
			<Modal active={this.state.modal}>
				<form action="#" onSubmit={e => this.updateReport(e)}>
					<div className="title-page">
						<div className="title">
							<h2>Cambiar de estado</h2>
						</div>
					</div>
					<div className="divider"></div>
					{'uuid' in this.state.reporte && (
						<div className="legend" style={{ width: '100%', textAlign: 'left' }}>
							<strong>Estado actual: </strong>
							<span>{this.state.reporte.status}</span>
						</div>
					)}
					<div className="divider"></div>
					<Input type="select" title="Estado del reporte *" options={this.state.options} name="status" />
					<Input type="textarea" title="Cuéntale al usuario la evolución del proceso sobre su reporte *" placeholder="..." name="text" />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modal: false })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.modalUpdate()}
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/alerts" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Reportes</h2>
						<span>Getionar</span>
					</div>
					<div className="btns">
						<a
							className="btn"
							href="#"
							onClick={e => {
								e.preventDefault()
								this.printReport()
							}}>
							Imprimir
						</a>
						<a
							className="btn"
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ modal: true })
							}}>
							Cambiar de estado
						</a>
						{!('withPoints' in this.state.reporte) && (
							<a
								className="btn"
								href="#"
								onClick={e => {
									e.preventDefault()
									this.calculatePoints()
								}}>
								Asignar puntos al usuario
							</a>
						)}
					</div>
				</div>
				<div className="columns">
					<div className="column" style={{ width: '30%' }}>
						<div className="status-bid card" style={{ backgroundColor: this.state.reporte.status === 'Cerrado' ? '#009900' : '#959595' }}>
							{this.state.reporte.status}
						</div>
						<div className="card">
							{'uuid' in this.state.reporte && (
								<>
									<div className="container-avatar">
										<span className="avater">{this.state.reporte.user.nickname[0].toUpperCase()}</span>
									</div>
									{'withPoints' in this.state.reporte && (
										<div className="legend">
											<strong>Puntos ganados: </strong>
											<span>{this.state.reporte.withPoints}</span>
										</div>
									)}
									<div className="legend">
										<strong>Usuario: </strong>
										<span>{this.state.reporte.user.nickname}</span>
									</div>
									<div className="legend">
										<strong>Identificador: </strong>
										<span>{this.state.reporte.uuid}</span>
									</div>
									<div className="legend">
										<strong>Fecha: </strong>
										<span>{this.state.reporte.date.split(' - ')[0]}</span>
									</div>
									<div className="legend">
										<strong>Hora: </strong>
										<span>{this.state.reporte.date.split(' - ')[1]}</span>
									</div>
									<div className="legend">
										<strong>Latitud: </strong>
										<span>{this.state.reporte.latitude}</span>
									</div>
									<div className="legend">
										<strong>Longitud: </strong>
										<span>{this.state.reporte.longitude}</span>
									</div>
									<div className="legend">
										<strong>Ubicación: </strong>
										<a
											target="_blank"
											href={'http://www.google.com/maps/place/' + this.state.reporte.latitude + ',' + this.state.reporte.logitude}
											rel="noreferrer">
											Ver en google Maps
										</a>
									</div>
									<div className="legend">
										<strong>Tipo de evidencia: </strong>
										<span>{this.state.reporte.file_type === 'imagen' ? 'Imagen' : 'Video'}</span>
									</div>
									{'typeReport' in this.state.reporte && (
										<div className="legend">
											<strong>Tipo de reporte: </strong>
											<NavLink to={'/admin/typealerts/edit/' + this.state.reporte.typeReport.uuid}>{this.state.reporte.typeReport.name}</NavLink>
										</div>
									)}
									{'data' in this.state.reporte && (
										<>
											<div className="legend">
												<strong>Nombre del infractor: </strong>
												<span>{this.state.reporte.data.name || 'N/A'}</span>
											</div>
											<div className="legend">
												<strong>Dirección del infractor: </strong>
												<span>{this.state.reporte.data.address || 'N/A'}</span>
											</div>
											<div className="legend">
												<strong>Puntos de referencia: </strong>
												<span>{this.state.reporte.data.reference || 'N/A'}</span>
											</div>
										</>
									)}
									<div className="legend">
										<strong>Observaciones: </strong>
									</div>
									<p>{this.state.reporte.notas || 'N/A'}</p>
								</>
							)}
						</div>
					</div>
					<div className="column" style={{ width: '68%' }}>
						<div className="card">
							<div className="container-reporte">
								{'uuid' in this.state.reporte && (
									<>
										{this.state.reporte.file_type === 'imagen' ? (
											<img src={e.urlBucket + this.state.reporte.name_file} />
										) : (
											<video src={e.urlBucket + this.state.reporte.name_file} controls>
												<p>Su navegador no soporta vídeos HTML5.</p>
											</video>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="divider"></div>
			</>
		)
	}
}
export default withParams(Page)
