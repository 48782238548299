import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const bcrypt = require('bcryptjs')
const users = new DB('users')
const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/

export default class CreateCollector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false
		}
	}

	handleSubmit = async event => {
		event.preventDefault()
		try {
			const data = new FormData(event.currentTarget)
			if (data.get('name') === '' || data.get('email') === '' || data.get('password') === '' || data.get('password_confirmation') === '') {
				Alert('Debes completar la informacion para proceder con el registro del recolector', 'danger')
				return
			}
			if (emailRegex.test(data.get('email')) !== true) {
				Alert('Debes ingresar un correo valido', 'danger')
				return
			}
			if (data.get('password').length < 7) {
				Alert('La contraseña debe tener 8 caracteres como minímo', 'danger')
				return
			}
			if (data.get('password') !== data.get('password_confirmation')) {
				Alert('Las contraseñas no coinciden', 'danger')
				return
			}
			this.setState({ loading: true })
			const existResult = await users.get({ user: data.get('email').trim() })
			if (existResult.status !== true) {
				this.setState({ loading: false })
				Alert('¡Ooops! al parecer tenemos problemas para extablecer conexión', 'danger')
				return
			}
			if (existResult.data.Item) {
				this.setState({ loading: false })
				Alert('Este correo ya se encuentra registrado', 'danger')
				return
			}
			const doc = 'ECO_' + new Date().getTime().toString(36) // un documento ficticio, más adelante los recolectores pueden actualizar sus datos
			const newUser = {
				user: data.get('email').trim(),
				name: data.get('name').trim(),
				lastName: 'Recolector ECOCITY',
				email: data.get('email').trim(),
				password: bcrypt.hashSync(data.get('password'), 8),
				terminos: true,
				created_at: new Date().getTime(),
				updated_at: new Date().getTime(),
				typeDoc: 'CC',
				doc,
				contractPublicServices: '0000001',
				typeFactura: 'Energía',
				phone: '0000000000',
				address: 'Sin dirección',
				code: doc,
				is_collector: true,
				points: 0,
				updatedPointsAt: new Date().getTime() // verificar quien llego primero a los puntos del nivel
			}
			const registerResult = await users.create(newUser)
			if (registerResult.status !== true) {
				this.setState({ loading: false })
				console.log(registerResult)
				Alert('Ocurrió un error al registrar el usuario', 'danger')
				return
			}
			Alert('Usuario recolector registrado correctamente', 'success')
			this.props.navigate('admin/users')
		} catch (error) {
			console.error(error)
			Alert('Ocurrió un error al registrar el usuario', 'danger')
			this.setState({ loading: false })
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/users" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Nuevo usuario recolector</h2>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre del recolector *" placeholder="Ingresa el nombre" name="name" />
							<Input type="email" title="Correo del recolector *" placeholder="Ingresa el correo" name="email" />
						</div>
						<div className="column">
							<Input type="password" title="Contraseña *" placeholder="********" name="password" />
							<Input type="password" title="Confirmar contraseña *" placeholder="********" name="password_confirmation" />
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
