export default function Loader(props) {
	return (
		<>
			{props.active && (
				<div className="loader">
					<span className="loader-circle"></span>
					{props.message && <p>{props.message}</p>}
				</div>
			)}
		</>
	)
}
