import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		console.log('Proximamente...')
	}

	render() {
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Tienda</h2>
						<span>...</span>
					</div>
				</div>
				<div className="card">
					<span className="nothing">Proximamente...</span>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
