import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'
import Loader from 'componentes/loader'
import Modal from 'componentes/modal'
import Input from 'componentes/input'
import QRCode from 'qrcode.react'
import { NavLink } from 'react-router-dom'
import { Alert, parseDate, Levenshtein } from 'utiles/functions'
import e from 'utiles/settings'
import DB from 'utiles/db'
const users = new DB('users')
let allUsers = []
class Users extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			total: 0,
			totalCollectors: 0,
			totalAdmins: 0,
			data: [],
			// -------
			modalQr: false,
			userQr: null,
			// ----------
			modalResetPoints: false,
			pointsToReset: 0
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			const result = await users.getAll()
			if (result.status !== true) {
				console.error(result)
				this.setState({ loading: false })
				Alert('Error al cargar los usuarios', 'danger')
				return
			}
			allUsers = result.data.Items
			this.setState({
				data: result.data.Items,
				total: result.data.Count,
				totalCollectors: result.data.Items.filter(user => user.is_collector).length,
				totalAdmins: result.data.Items.filter(user => user.is_admin).length,
				loading: false
			})
		} catch (error) {
			console.error(error)
			this.setState({ loading: false })
			Alert('Error al cargar los usuarios', 'danger')
		}
	}

	async resetPoints() {
		try {
			this.setState({ loading: true })
			const points = Number(this.state.pointsToReset) || 0
			const usersToUpdatePs = []
			const updatedPointsAt = Date.now()
			for (const user of allUsers) {
				// Solo los usuarios que tengan puntos (que han participado)
				if (![null, '', undefined].includes(user.points)) {
					usersToUpdatePs.push(users.update({ points, updatedPointsAt }, { user: user.user }))
				}
			}
			const result = await Promise.all(usersToUpdatePs)
			if (result.some(r => r.status !== true)) {
				console.error(result)
				this.setState({ loading: false })
				Alert('Error resetear los puntos de los usuarios', 'danger')
				return
			}
			this.setState({ loading: false, modalResetPoints: false })
			Alert('Puntos de los usuarios reseteados con exito')
		} catch (error) {
			console.error(error)
			this.setState({ loading: false })
			Alert('Error resetear los puntos de los usuarios', 'danger')
		}
	}

	next() {
		this.setState({ loading: true }, () => {
			users.next().then(data => {
				const u1 = this.state.data
				const u2 = u1.concat(data.data.Items)
				this.setState({ data: u2, loading: false })
			})
		})
	}

	getRole(isAdmin, isCollector) {
		if (isAdmin) {
			return <strong>Administrador</strong>
		}
		if (isCollector) {
			return <strong>Recolector</strong>
		}
		return 'Usuario'
	}

	modalQr() {
		return (
			<Modal active={this.state.modalQr}>
				<div>
					<div className="title-page">
						<div className="title">
							<h2>Código QR: {this.state.userQr?.name || this.state.userQr?.nickname}</h2>
						</div>
					</div>
					<div className="divider"></div>
					<div style={{ height: 'auto', margin: '0 auto', maxWidth: 350, width: '100%' }}>
						<QRCode
							id="qrCodeCanvas"
							size={350}
							style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
							value={btoa(
								JSON.stringify({
									key: 'ECOCODE',
									user: {
										name: this.state.userQr?.name || this.state.userQr?.nickname,
										email: this.state.userQr?.user
									}
								})
							)}
							imageSettings={{
								src: require('img/icono.png'),
								height: 45,
								width: 45
							}}
						/>
					</div>
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								const qrCodeURL = document.getElementById('qrCodeCanvas').toDataURL('image/png').replace('image/png', 'image/octet-stream')
								const aEl = document.createElement('a')
								const name = this.state.userQr?.name || this.state.userQr?.nickname || new Date().getTime()
								aEl.href = qrCodeURL
								aEl.download = 'QR_ECOCITY_' + name.toUpperCase().replace(/ /g, '-') + '.png'
								document.body.appendChild(aEl)
								aEl.click()
								document.body.removeChild(aEl)
							}}
							className="btn">
							Descargar QR
						</a>
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalQr: false })
							}}
							className="btn">
							Cerrar
						</a>
					</div>
				</div>
			</Modal>
		)
	}

	modalResetPoints() {
		return (
			<Modal active={this.state.modalResetPoints}>
				<div>
					<div className="title-page">
						<div className="title">
							<h2>Resetear puntos de los usuarios</h2>
						</div>
					</div>
					<div className="divider"></div>
					<Input
						type="number"
						title="Puntos por defecto (Si no se ingresa un valor los puntos quedarán en 0 por defecto)"
						placeholder="Ingresa la cantidad de puntos"
						name="pointsToReset"
						value={this.state.pointsToReset}
						change={points => this.setState({ pointsToReset: points })}
					/>
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ modalResetPoints: false })
							}}
							className="btn">
							Cerrar
						</a>
						<a
							href="#"
							onClick={e => {
								e.preventDefault()
								this.resetPoints()
							}}
							className="btn">
							Resetear puntos
						</a>
					</div>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.modalQr()}
				{this.modalResetPoints()}
				<Loader active={this.state.loading} />
				<div className="title-page">
					<div className="title">
						<a href="#" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</a>
						<h2>Usuarios</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/admin/campains" className="btn">
							Envio de notificaciónes
						</NavLink>
						<a href="#" className="btn" onClick={e => this.setState({ modalResetPoints: true })}>
							Resetear puntos
						</a>
						<NavLink to="/admin/users/create/collector" className="btn">
							Registrar usuario recolector
						</NavLink>
					</div>
				</div>
				<div className="grid g5" style={{ marginBottom: 20 }}>
					<div className="card">
						<div className="item-widget">
							<span>Usuarios registrados</span>
							<strong>{this.state.total}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Usuarios Recolectores</span>
							<strong>{this.state.totalCollectors}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Usuarios Administradores</span>
							<strong>{this.state.totalAdmins}</strong>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input
								type="text"
								placeholder="Escribe algo.."
								onChange={event => {
									const val = event.target.value
									if (val === '' || val === null || val === undefined) {
										this.setState({ data: allUsers })
										return
									}
									if (val.length < 3) {
										return
									}
									const filteredUsers = allUsers.filter(user => {
										if (!user.name) {
											return false
										}
										const name = user.name.toLowerCase().split(' ')
										for (const palabra of name) {
											if (
												Levenshtein(palabra, val.toLowerCase()) < 3 ||
												palabra.indexOf(val.toLowerCase()) > -1 ||
												user.nickname?.indexOf(val.toLowerCase()) > -1 ||
												user.user?.indexOf(val.toLowerCase()) > -1 ||
												user.code?.toLowerCase().indexOf(val.toLowerCase()) > -1
											) {
												return true
											}
										}
										return false
									})
									this.setState({ data: filteredUsers })
								}}
							/>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Correo</th>
								<th>Teléfono</th>
								<th>Rol</th>
								<th>Puntos</th>
								<th>Código</th>
								<th>Código referente</th>
								<th>Fecha de registro</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(user => (
								<tr key={user.user}>
									<td>
										<NavLink className="item-pro" to={'show/' + user.user}>
											{user.avatar ? (
												<img src={e.urlBucket + user.avatar} alt="Avatar" width={35} height={35} />
											) : (
												<img src={require('img/user.png')} alt="Avatar" width={35} height={35} />
											)}
											<span>{user.name || user.nickname}</span>
										</NavLink>
									</td>
									<td style={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.user}</td>
									<td style={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.phone || 'No definido'}</td>
									<td>{this.getRole(user.is_admin, user.is_collector)}</td>
									<td>{user.points || '0'}</td>
									<td>{user.code || 'No definido'}</td>
									<td>{user.codeRefer || 'No definido'}</td>
									<td>{parseDate(user.created_at, true)}</td>
									<td>
										<a
											href="#"
											style={{ display: 'block', textAlign: 'center' }}
											onClick={e => {
												e.preventDefault()
												this.props.navigate('admin/users/show/' + user.user)
											}}
											className="btn-edit">
											Ver usuario
										</a>
										<a
											href="#"
											style={{ display: 'block', marginTop: 5, textAlign: 'center' }}
											onClick={e => {
												e.preventDefault()
												this.setState({ modalQr: true, userQr: user })
											}}
											className="btn-delete">
											Generar QR
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{/* {this.state.data.length < this.state.total && (
						<a
							className="load-more"
							href="#"
							onClick={event => {
								event.preventDefault()
								this.next()
							}}>
							Cargar más resultados
						</a>
					)} */}
				</div>
			</>
		)
	}
}
export default AnimateScreen(Users)
