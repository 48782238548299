const e = {
	// Accesos AWS
	// -----------
	region: 'us-east-2',
	accessKeyId: 'AKIAW73R62G3CPXNFN2M',
	secretAccessKey: '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',
	bucket: 'svrapp',
	urlBucket: 'https://svrapp.s3.us-east-2.amazonaws.com/'
}
export default e
