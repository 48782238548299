import { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, postNotification } from 'utiles/functions'
import Back from 'componentes/back'
import DB from 'utiles/db'
const users = new DB('users')
const cities = new DB('cities')
const neighborhood = new DB('neighborhood')
const ecas = new DB('ecas')
const campains = new DB('campains')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			emails: '',
			cities: [],
			selectedCity: '',
			neighborhoods: [],
			selectedNeighborhoods: '',
			ecas: [],
			selectedEca: '',
			points: '',
			users: [],
			messageLoading: 'Cargando...',
			filters: [],
			title: '',
			message: ''
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		const c = (await cities.getAll()).data.Items.map(item => {
			return { val: item.uuid, text: item.name }
		})
		c.unshift({ val: '', text: 'Seleccionar...' })
		const n = (await neighborhood.getAll()).data.Items.map(item => {
			return { val: item.uuid, text: item.name }
		})
		n.unshift({ val: '', text: 'Seleccionar...' })
		const e = (await ecas.getAll()).data.Items.map(item => {
			return { val: item.uuid, text: item.name }
		})
		e.unshift({ val: '', text: 'Seleccionar...' })
		this.setState({ cities: c, neighborhoods: n, ecas: e, loading: false })
	}

	async calculateUsers() {
		if (this.state.filters.length > 0) {
			let query = ``
			const lables = {}
			const values = {}
			this.state.filters.forEach(f => {
				if (f.type === 'emails') {
					query += query.length > 0 ? ` AND contains(#user, :emails)` : `contains(:emails, #user)`
					lables['#user'] = 'user'
					values[':emails'] = f.val.replaceAll(' ', '').trim().split(',')
				}

				if (f.type === 'city') {
					query += query.length > 0 ? ` AND #city_uuid = :city_uuid` : `#city_uuid = :city_uuid`
					lables['#city_uuid'] = 'city_uuid'
					values[':city_uuid'] = f.val
				}

				if (f.type === 'neighborhood') {
					query += query.length > 0 ? ` AND #neighborhood_uuid = :neighborhood_uuid` : `#neighborhood_uuid = :neighborhood_uuid`
					lables['#neighborhood_uuid'] = 'neighborhood_uuid'
					values[':neighborhood_uuid'] = f.val
				}

				if (f.type === 'eca') {
					query += query.length > 0 ? ` AND #eac_uuid = :eac_uuid` : `#eac_uuid = :eac_uuid`
					lables['#eac_uuid'] = 'eac_uuid'
					values[':eac_uuid'] = f.val
				}

				if (f.type === 'points') {
					query += query.length > 0 ? ` AND #points >= :points` : `#points >= :points`
					lables['#points'] = 'points'
					values[':points'] = Number(f.val)
				}
			})
			const us = (await users.query(query, values, lables)).data.Items
			this.setState({ users: us, loading: false })
		} else {
			const us = (await users.getAll()).data.Items
			this.setState({ users: us, loading: false })
		}
	}

	deleteUser(user) {
		const users = this.state.users.filter(u => u.user !== user)
		this.setState({ users })
	}

	async sendNotifications() {
		const promises = []
		this.state.users.forEach(user => {
			if (user.token) {
				promises.push(postNotification(user.token, this.state.title, this.state.message))
			}
		})
		await Promise.all(promises)
		await campains.create({
			uuid: uuidv4(),
			title: this.state.title,
			message: this.state.message,
			users: this.state.users.length,
			filters: this.state.filters
		})
		Alert('Campaña enviada con exito!')
		this.props.navigate('admin/campains')
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('title') !== '' && data.get('message') !== '') {
			if (data.get('title').length <= 50) {
				if (data.get('message').length <= 100) {
					this.setState({ loading: true }, () => {
						if (this.state.users.length > 0) {
							this.setState({ messageLoading: 'Enviando notificaciones' })
							this.sendNotifications()
						} else {
							this.setState({ messageLoading: 'Calculando usuarios' })
							this.calculateUsers()
						}
					})
				} else {
					Alert('El mensaje es demasiado largo, el tamaño máximo es de 100 caracteres.', 'danger')
				}
			} else {
				Alert('El titulo es demasiado largo, el tamaño máximo es de 50 caracteres.', 'danger')
			}
		} else {
			Alert('Debes ingresar el titulo y el mensaje de la notificación push', 'danger')
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message={this.state.messageLoading} />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Campañas</h2>
						<span>Crear campaña</span>
					</div>
					<div className="btns">
						{this.state.users.length > 0 && (
							<a
								href="#"
								className="btn"
								onClick={e => {
									e.preventDefault()
									this.setState({ users: [], loading: true, messageLoading: 'Calculando usuarios' }, () => {
										this.calculateUsers()
									})
								}}>
								Re-calcular usuarios
							</a>
						)}
						<button className="btn" type="submit">
							{this.state.users.length === 0 ? 'Calcular usuarios' : 'Enviar campaña'}
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input
								type="email"
								title="Correos electronicos separados por comas (,)"
								placeholder="..."
								name="emails"
								value={this.state.emails}
								change={val => {
									this.setState({ emails: val }, () => {
										let filters = this.state.filters
										if (val !== '') {
											if (filters.filter(f => f.type === 'emails').length === 0) {
												filters.push({
													type: 'emails',
													val
												})
											} else {
												filters = filters.map(f => {
													if (f.type === 'emails') {
														f.val = val
													}
													return f
												})
											}
										} else {
											filters = filters.filter(f => f.type !== 'emails')
										}
										this.setState({ filters })
									})
								}}
							/>
							<Input
								type="select"
								value={this.state.selectedCity}
								title="Seleccionar ciudad"
								placeholder="..."
								name="city"
								options={this.state.cities}
								change={val => {
									this.setState({ selectedCity: val }, () => {
										let filters = this.state.filters
										if (val !== '') {
											if (filters.filter(f => f.type === 'city').length === 0) {
												filters.push({
													type: 'city',
													val
												})
											} else {
												filters = filters.map(f => {
													if (f.type === 'city') {
														f.val = val
													}
													return f
												})
											}
										} else {
											filters = filters.filter(f => f.type !== 'city')
										}
										console.log(val)
										this.setState({ filters })
									})
								}}
							/>
							<Input
								type="select"
								value={this.state.selectedNeighborhoods}
								title="Seleccionar barrio"
								placeholder="..."
								name="neighborhood"
								options={this.state.neighborhoods}
								change={val => {
									this.setState({ selectedNeighborhoods: val }, () => {
										let filters = this.state.filters
										if (val !== '') {
											if (filters.filter(f => f.type === 'neighborhood').length === 0) {
												filters.push({
													type: 'neighborhood',
													val
												})
											} else {
												filters = filters.map(f => {
													if (f.type === 'neighborhood') {
														f.val = val
													}
													return f
												})
											}
										} else {
											filters = filters.filter(f => f.type !== 'neighborhood')
										}
										this.setState({ filters })
									})
								}}
							/>
							<Input
								type="select"
								value={this.state.selectedEca}
								title="Seleccionar ECA"
								placeholder="..."
								name="eca"
								options={this.state.ecas}
								change={val => {
									this.setState({ selectedEca: val }, () => {
										let filters = this.state.filters
										if (val !== '') {
											if (filters.filter(f => f.type === 'eca').length === 0) {
												filters.push({
													type: 'eca',
													val
												})
											} else {
												filters = filters.map(f => {
													if (f.type === 'eca') {
														f.val = val
													}
													return f
												})
											}
										} else {
											filters = filters.filter(f => f.type !== 'eca')
										}
										this.setState({ filters })
									})
								}}
							/>
							<Input
								type="number"
								title="Mínimo de puntos"
								placeholder="..."
								name="points"
								value={this.state.points}
								change={val => {
									this.setState({ points: val }, () => {
										let filters = this.state.filters
										if (val !== '') {
											if (filters.filter(f => f.type === 'points').length === 0) {
												filters.push({
													type: 'points',
													val
												})
											} else {
												filters = filters.map(f => {
													if (f.type === 'points') {
														f.val = val
													}
													return f
												})
											}
										} else {
											filters = filters.filter(f => f.type !== 'points')
										}
										this.setState({ filters })
									})
								}}
							/>
						</div>
						<div className="column">
							<Input
								type="text"
								title="Titulo de la campaña"
								placeholder="..."
								name="title"
								value={this.state.title}
								change={val => this.setState({ title: val })}
							/>
							<Input
								type="textarea"
								title="Mensaje de la campaña"
								placeholder="..."
								name="message"
								value={this.state.message}
								change={val => this.setState({ message: val })}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<div className="title-page">
						<div className="title">
							<h2>Cantidad de usuarios: {this.state.users.length}</h2>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Correo</th>
								<th>Teléfono</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.users.map(user => (
								<tr key={user.email}>
									<td>{user.name ? user.name : 'N/A'}</td>
									<td>{user.email}</td>
									<td>{user.phone ? user.phone : 'N/A'}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.deleteUser(user.email)
											}}
											className="btn-edit">
											Eliminar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</form>
		)
	}
}
