import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'
import Loader from 'componentes/loader'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const soli = new DB('need_collector')
const repo = new DB('reports')
const u = new DB('users')
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			registred: 0,
			collection: 0,
			reports: 0,
			orders: 0,
			elearning: 0,
			reportes: [],
			needCollection: []
		}
	}

	componentDidMount() {
		this.init()
		this.latesReports()
		this.pendindCollection()
	}

	async init() {
		try {
			const us = await u.count('#u <> :n', { ':n': 'null' }, { '#u': 'user' })
			if (us.status) {
				this.setState({ registred: us.data.Count })
			}
			const ss = await soli.count('not contains(#s, :s)', { ':s': 'Recolección finalizada' }, { '#s': 'status' })
			if (ss.status) {
				this.setState({ collection: ss.data.Count })
			}
			const rr = await repo.count('#c <> :u', { ':u': null }, { '#c': 'cierre' })
			if (rr.status) {
				this.setState({ reports: rr.data.Count })
			}
		} catch (err) {
			console.log(err)
		}
	}

	async latesReports() {
		const reports = await repo.paginate(10)
		reports.data.Items.map(item => {
			if (item.evaluacion === undefined && item.intervenciones === undefined && item.cierre === undefined) {
				item.status = 'Nuevo'
			} else if (item.evaluacion !== undefined && item.intervenciones === undefined && item.cierre === undefined) {
				item.status = 'En evaluación'
			} else if (item.evaluacion !== undefined && item.intervenciones !== undefined && item.cierre === undefined) {
				item.status = 'En intervenciones'
			} else if (item.evaluacion !== undefined && item.intervenciones !== undefined && item.cierre !== undefined) {
				item.status = 'Cerrado'
			} else {
				item.status = 'Nuevo'
			}
			return item
		})
		this.setState({
			reportes: reports.data.Items
		})
	}

	async pendindCollection() {
		const needCollectionResult = await soli.query('not contains(#s, :s)', { ':s': 'Recolección finalizada' }, { '#s': 'status' })
		if (needCollectionResult.status !== true) {
			console.log(needCollectionResult)
			this.setState({ loading: false })
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
			return
		}
		this.setState({
			needCollection: needCollectionResult.data.Items,
			loading: false
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<h2>Inicio</h2>
						<span>Panel</span>
					</div>
				</div>

				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Usuarios registrados</span>
							<strong>{this.state.registred}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Solicitudes de recolección pendientes</span>
							<strong>{this.state.collection}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Reportes</span>
							<strong>{this.state.reports}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Compras en tienda</span>
							<strong>{this.state.orders}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Compras en E-learning</span>
							<strong>{this.state.elearning}</strong>
						</div>
					</div>
				</div>
				<div className="columns" style={{ marginTop: 20 }}>
					<div className="column">
						<div className="card">
							<div className="title-page">
								<div className="title">
									<h2>Solicitudes de recolección pendientes</h2>
								</div>
							</div>
							<table className="table">
								<thead>
									<tr>
										<th>E.C.A</th>
										<th>Usuario</th>
										<th>Fecha</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{this.state.needCollection.map(collector => (
										<tr key={collector.uuid}>
											<td>{collector.eca.name}</td>
											<td>{collector.user.name}</td>
											<td>{collector.fecha}</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.props.navigate('admin/need_collector/' + collector.uuid)
													}}
													className="btn-edit">
													Gestionar
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="column">
						<div className="card">
							<div className="title-page">
								<div className="title">
									<h2>Ultimos reportes</h2>
								</div>
							</div>
							<table className="table">
								<thead>
									<tr>
										<th>Usuario</th>
										<th>Fecha</th>
										<th>Hora</th>
										<th>Estado</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{this.state.reportes.map(report => (
										<tr key={report.uuid}>
											<td>{report.user.nickname || report.user.name}</td>
											<td>{report.date.split(' - ')[0]}</td>
											<td>{report.date.split(' - ')[1]}</td>
											<td>
												<span className={`status ${report.status === 'Cerrado' ? 'Activo' : 'Inactivo'}`}>{report.status}</span>
											</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.props.navigate('admin/alerts/edit/' + report.uuid)
													}}
													className="btn-edit">
													Getionar
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
