import { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Menu from 'componentes/menu'
import Header from 'componentes/header'
import Footer from 'componentes/footer'

function Dashboard(props) {
	const [mini, setMini] = useState(false)
	if (props.user !== null) {
		return (
			<>
				<div id="notifications"></div>
				<main className={`${mini ? 'mini-menu' : ''}`}>
					<Menu setUser={props.setUser} navigate={props.navigate} user={props.user} />
					<div className="content">
						<Header setMini={setMini} mini={mini} user={props.user} />
						<aside>
							<Outlet />
						</aside>
						<Footer />
					</div>
				</main>
			</>
		)
	} else {
		return <Navigate to="/" />
	}
}

export default Dashboard
