import { NavLink } from 'react-router-dom'

export default function Menu(props) {
	return (
		<nav>
			<img className="logo" src={require('img/logo-blanco.png')} />
			<strong>MENU</strong>
			<ul>
				{props.user.is_admin === true && (
					<>
						<li>
							<NavLink to="/admin/inicio">
								<img src={require('img/home.svg').default} height={18} width={18} /> Inicio
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/users">
								<img src={require('img/users.svg').default} width={18} height={18} /> Usuarios
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/need_collector">
								<img src={require('img/providers.svg').default} width={18} height={18} /> Recolección
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/alerts">
								<img src={require('img/danger.svg').default} width={18} height={18} /> Reportes
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/ecommerce">
								<img src={require('img/products.svg').default} width={18} height={18} /> Tienda
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/elearning">
								<img src={require('img/book-saved.svg').default} width={18} height={18} /> E-learning
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/stats">
								<img src={require('img/chart-21.svg').default} width={18} height={18} /> Estadisticas
							</NavLink>
						</li>
						<li>
							<NavLink to="/admin/settings">
								<img src={require('img/settings_w.svg').default} width={18} height={18} /> Configuración
							</NavLink>
						</li>
					</>
				)}
				{props.user.rol === 'ECA' && (
					<>
						<li>
							<NavLink to="/admin/need_collector/admin/gestor-eca">
								<img src={require('img/providers.svg').default} width={18} height={18} /> Recolección
							</NavLink>
						</li>
						<li>
							<NavLink to={`/admin/settings/gatherers/${props.user.eca}`}>
								<img src={require('img/users.svg').default} height={18} width={18} /> Recolectores
							</NavLink>
						</li>
					</>
				)}
			</ul>
			<a
				href="#"
				className="logout"
				onClick={event => {
					event.preventDefault()
					localStorage.setItem('@eco_user', '')
					props.setUser(null)
					props.navigate('/', { replace: true })
				}}>
				<img src={require('img/arrow-left.svg').default} width={18} height={18} /> Cerrar sesión
			</a>
			<p className="brand">Desarrollado por Hexa</p>
		</nav>
	)
}
