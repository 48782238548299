import { Component } from 'react'
import Loader from 'componentes/loader'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const users = new DB('users')
const bcrypt = require('bcryptjs')

export default class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
	}

	componentDidMount() {
		const user = localStorage.getItem('@eco_user')
		if (user !== null && user !== undefined && user !== '') {
			this.setState({ loading: false }, () => {
				const u = JSON.parse(user)
				this.props.setUser(u)
				if (u.is_admin) {
					this.props.navigate('/admin/inicio')
				}
			})
		} else {
			this.setState({ loading: false })
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const email = data.get('email')
		const pass = data.get('password')
		if (email !== '' && pass !== '') {
			this.setState({ loading: true }, async () => {
				const r = await users.find({ user: data.get('email') })
				console.log(r)
				if (r.status) {
					if ('Item' in r.data) {
						if (r.data.Item.is_admin || r.data.Item.rol === 'ECA') {
							const comparePass = bcrypt.compareSync(pass, r.data.Item.password)
							if (comparePass) {
								localStorage.setItem('@eco_user', JSON.stringify(r.data.Item))
								this.setState({ loading: false }, () => {
									this.props.setUser(r.data.Item)
									if (r.data.Item.is_admin) {
										this.props.navigate('/admin')
									}
									if (r.data.Item.rol === 'ECA') {
										this.props.navigate('/admin/need_collector/admin/gestor-eca')
									}
								})
							} else {
								this.setState({ loading: false }, () => {
									Alert('La contraseña no es correcta.', 'danger')
								})
							}
						} else {
							Alert('SU usuario no cuenta con permisos para acceder.', 'danger')
						}
					} else {
						this.setState({ loading: false }, () => {
							Alert('El correo electrónico no se encuentra registrado', 'danger')
						})
					}
				} else {
					this.setState({ loading: false }, () => {
						Alert('¡Ooops!, tenemos problemas de conexión, intentalo de nuevo', 'danger')
					})
				}
			})
		} else {
			Alert('Debes ingresar un correo y una contraseña', 'danger')
		}
	}

	render() {
		return (
			<>
				<div id="notifications"></div>
				<div className="container-login">
					<form onSubmit={this.handleSubmit} action="#">
						<Loader active={this.state.loading} />
						<h1>Iniciar sesión</h1>
						<span>Sistema de gestión para App Eco City</span>
						<div className="divider" />
						<div className="input-group">
							<label>Correo electrónico</label>
							<input type="text" name="email" placeholder="Ingresa tu correo" />
						</div>
						<div className="input-group">
							<label>Contraseña</label>
							<input type="password" name="password" placeholder="*******" />
						</div>
						<div className="divider" />
						<button className="btn">Ingresar</button>
					</form>
				</div>
			</>
		)
	}
}
