import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
import { v4 as uuidv4 } from 'uuid'
const typeReport = new DB('type_report')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		this.setState({ loading: true }, () => {
			if (data.get('name') !== '' && data.get('points') !== '') {
				typeReport
					.create({
						uuid: uuidv4(),
						name: data.get('name'),
						points: data.get('points'),
						status: this.state.status
					})
					.then(r => {
						if (r.status) {
							this.props.navigate('/admin/typealerts')
							Alert('Tipo de alerta registrado con exito')
						} else {
							this.setState({ loading: false }, () => {
								Alert('Tenemos problemas de conexión, intentalo de nuevo', 'danger')
							})
						}
					})
			} else {
				Alert('Ingresa la información requerida (*)', 'danger')
			}
		})
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/typealerts" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Tipos de alertas</h2>
						<span>Nuevo tipo</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
							<Input type="text" title="Titulo del tipo de alerta *" placeholder="Ingresa el nombre" name="name" />
							<Input type="number" title="Puntos para el usuario *" placeholder="0" name="points" />
							<Input
								type="checkbox"
								title="Estado (No publicado / Publicado)"
								name="status"
								change={v => this.setState({ status: v })}
								active={this.state.status}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
