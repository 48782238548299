import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'
import Loader from 'componentes/loader'
import { NavLink } from 'react-router-dom'
import { Alert, Confirm } from 'utiles/functions'
import DB from 'utiles/db'
const waste = new DB('waste')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: []
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		waste
			.getAll()
			.then(data => {
				if (data.status) {
					this.setState({ data: data.data.Items, loading: false })
				} else {
					Alert('¡Ooops! tenemos problemas de conexión')
					this.setState({ loading: false })
				}
			})
			.catch(err => {
				console.log(err)
				Alert('¡Ooops! tenemos problemas de conexión')
				this.setState({ loading: false })
			})
	}

	deleteWaste(uuid) {
		Confirm('¿Realmente desea eliminar este tipo de residuo?', () => {
			this.setState({ loading: true }, () => {
				waste.delete({ uuid }).then(r => {
					if (r.status) {
						this.setState({ loading: false })
						this.init()
					} else {
						console.log(r)
						this.setState({ loading: false })
						Alert('¡Ooops!, tenemos problemas de conexión', 'danger')
					}
				})
			})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/settings" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Tipos de residuos</h2>
					</div>
					<NavLink to="/admin/typewaste/create" className="btns">
						<a href="#" className="btn">
							Nuevo tipo
						</a>
					</NavLink>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Residuo</th>
								<th>Unidad</th>
								<th>Precio por unidad</th>
								<th>Puntos por unidad</th>
								<th>Estado</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(was => (
								<tr key={was.uuid}>
									<td>{was.title}</td>
									<td>{was.unit || 'N/A'}</td>
									<td>{was.price_unit || 0}</td>
									<td>{was.points_unit || 0}</td>
									<td>
										{was.status !== undefined ? (
											<span className={`status ${was.status ? 'Activo' : 'Inactivo'}`}>{was.status ? 'Activo' : 'Inactivo'}</span>
										) : (
											<p>N/A</p>
										)}
									</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('admin/typewaste/edit/' + was.uuid)
											}}
											className="btn-edit">
											Editar
										</a>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.deleteWaste(was.uuid)
											}}
											className="btn-delete">
											Eliminar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
