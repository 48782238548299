import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import AnimateScreen from 'componentes/animateScreen'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import { Alert } from 'utiles/functions'
import Back from 'componentes/back'
const campains = new DB('campains')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: []
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		const data = (await campains.getAll()).data.Items
		this.setState({ data, loading: false })
	}

	copy(campain) {
		Alert('Proximamente...')
	}

	delete(campain) {
		Alert('Proximamente...')
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Campañas</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/admin/campains/create" className="btn">
							Nueva campaña
						</NavLink>
					</div>
				</div>
				<div className="card">
					<table className="table">
						<thead>
							<tr>
								<th>Título</th>
								<th>Mensaje</th>
								<th>Cantidad usuarios</th>
								<th>Fecha</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(campain => (
								<tr key={campain.uuid}>
									<td>{campain.title}</td>
									<td>{campain.message}</td>
									<td>{campain.users}</td>
									<td>{campain.createdAt}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.copy(campain)
											}}
											className="btn-edit">
											Copiar campaña
										</a>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.delete(campain)
											}}
											className="btn-delete">
											Eliminar campaña
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
