import { Component } from 'react'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import { Alert, Price, postNotification } from 'utiles/functions'
import Modal from 'componentes/modal'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import Back from 'componentes/back'
const needCollector = new DB('need_collector')
const waste = new DB('waste')
const users = new DB('users')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modalResiduos: false,
			data: {},
			status: '',
			wastesSelected: [],
			ModalWastesSelected: [],
			wastes: [],
			avaluoReciclaje: 0,
			puntaje: 0,
			estadoOriginal: ''
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		waste.getAll().then(data => {
			if (data.status) {
				this.setState({ wastes: data.data.Items })
			}
		})
		needCollector.get({ uuid: this.props.params.uuid }).then(data => {
			if (data.status) {
				const ModalWastesSelected = {}
				for (const waste of data.data.Item.wastesSelected) {
					ModalWastesSelected[waste.uuid] = true
				}
				this.setState({
					data: data.data.Item,
					loading: false,
					status: data.data.Item.status,
					wastesSelected: data.data.Item.wastesSelected,
					ModalWastesSelected,
					puntaje: data.data.Item.points ? data.data.Item.points : 0,
					avaluoReciclaje: data.data.Item.avaluoReciclaje ? data.data.Item.avaluoReciclaje : 0,
					estadoOriginal: data.data.Item.status
				})
			} else {
				this.setState({ loading: false })
				Alert('Tenemos problemas de conexión', 'danger')
			}
		})
	}

	changeCantidadRecolectada(uuid, val) {
		const wastesSelected = [...this.state.wastesSelected]
		// actualizar la cantidad de unidades recolectadas
		wastesSelected.find(waste => waste.uuid === uuid).recolected = Number(val)
		// actualizar puntos
		wastesSelected.find(waste => waste.uuid === uuid).points = Number(this.state.wastes.find(waste => waste.uuid === uuid).points_unit) * Number(val)
		// actualizar
		wastesSelected.find(waste => waste.uuid === uuid).price = Number(this.state.wastes.find(waste => waste.uuid === uuid).price_unit) * Number(val)
		this.setState({ wastesSelected }, () => {
			this.calcularPuntos()
			this.calcularAvaluo()
		})
	}

	getPoints(uuid) {
		return this.state.wastesSelected.find(waste => waste.uuid === uuid).points
			? this.state.wastesSelected.find(waste => waste.uuid === uuid)?.points
			: 0
	}

	valorSeleccionResiduo(ModalWastesSelected, uuid) {
		return ModalWastesSelected[uuid] ? 'on' : ''
	}

	seleccionResiduos(uuid) {
		const { ModalWastesSelected } = this.state
		this.setState({ ModalWastesSelected: { ...ModalWastesSelected, [uuid]: !ModalWastesSelected[uuid] } }, () => this.actualizarResiduos())
	}

	actualizarResiduos() {
		const nuevosResiduos = Object.keys(this.state.ModalWastesSelected)
			.filter(uuid => this.state.ModalWastesSelected[uuid])
			.map(uuid => ({
				uuid,
				detalles: this.state.wastes.find(waste => waste.uuid === uuid)?.descripcion,
				title: this.state.wastes.find(waste => waste.uuid === uuid)?.title,
				recolected: this.state.wastesSelected.find(waste => waste.uuid === uuid)?.recolected
					? this.state.wastesSelected.find(waste => waste.uuid === uuid)?.recolected
					: '',
				points: this.state.wastesSelected.find(waste => waste.uuid === uuid)?.points
					? this.state.wastesSelected.find(waste => waste.uuid === uuid)?.points
					: 0,
				price: this.state.wastesSelected.find(waste => waste.uuid === uuid)?.price
					? this.state.wastesSelected.find(waste => waste.uuid === uuid)?.price
					: 0
			}))
		console.log(nuevosResiduos)
		this.setState({ wastesSelected: nuevosResiduos }, () => {
			this.calcularPuntos()
			this.calcularAvaluo()
		})
	}

	eliminarResiduo = uuid =>
		this.setState(({ ModalWastesSelected, wastesSelected }) => ({
			ModalWastesSelected: (({ [uuid]: _, ...rest }) => rest)(ModalWastesSelected),
			wastesSelected: wastesSelected.filter(waste => waste.uuid !== uuid)
		}))

	calcularPuntos() {
		const { wastesSelected } = this.state
		let puntaje = 0
		for (const waste of wastesSelected) {
			puntaje += Number(waste.points)
		}
		this.setState({ puntaje })
	}

	calcularAvaluo() {
		const { wastesSelected } = this.state
		let avaluoReciclaje = 0
		for (const waste of wastesSelected) {
			avaluoReciclaje += Number(waste.price)
		}
		this.setState({ avaluoReciclaje })
	}

	validarCampos() {
		let validadorCantidades = true
		for (const waste of this.state.wastesSelected) {
			if ((Number(waste.recolected) <= 0 || waste.recolected === undefined) && waste.recolected !== '') {
				validadorCantidades = false
			}
		}
		if (validadorCantidades) {
			this.guardarBD()
		} else {
			Alert('Por favor ingresa las cantidades de los residuos', 'danger')
		}
	}

	guardarBD() {
		// Actualizar solicitud
		this.setState({ loading: true }, async () => {
			await needCollector.update(
				{
					status: this.state.status,
					points: Number(this.state.puntaje),
					avaluoReciclaje: Number(this.state.avaluoReciclaje),
					wastesSelected: this.state.wastesSelected
				},
				{ uuid: this.props.params.uuid }
			)
			// Actualizar puntaje del usuario
			if (this.state.status === 'Recolección finalizada') {
				let user = await users.get({ user: this.state.data.user.user })
				user = user.data.Item
				const points = Number(user.points) + Number(this.state.puntaje)
				const updatedPointsAt = new Date().getTime()
				await users.update(
					{
						points,
						updatedPointsAt
					},
					{ user: this.state.data.user.user }
				)

				if ('token' in user) {
					postNotification(user.token, `Has ganado ${this.state.puntaje} puntos por tu solicitud de recolección.`, 'Recolección finalizada.')
				}
			}

			this.props.navigate('admin/need_collector')
			Alert('Recolección actualizada')
		})
	}

	modalResiduos() {
		return (
			<Modal active={this.state.modalResiduos} className="lamodal">
				<table className="table">
					<thead>
						<tr>
							<th>Residuo</th>
							<th>Descripción</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.wastes.map(waste => (
							<tr key={waste.uuid}>
								<td>
									<span>{waste.title}</span>
								</td>
								<td>{waste.descripcion}</td>
								<td>
									<Input
										type="checkbox"
										id=""
										title=""
										active={this.valorSeleccionResiduo(this.state.ModalWastesSelected, waste.uuid)}
										change={val => {
											this.seleccionResiduos(waste.uuid)
										}}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalResiduos: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				{this.modalResiduos()}
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Recolecciones</h2>
						<span>Gestionar</span>
					</div>
					<div className="btns">
						{this.state.estadoOriginal !== 'Recolección finalizada' && (
							<button onClick={() => this.validarCampos()} className="btn">
								Guardar
							</button>
						)}
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Estado</span>
							<strong>{this.state.data.status}</strong>
						</div>
					</div>

					<div className="card">
						<div className="item-widget">
							<span>Avalúo reciclaje</span>
							<strong>{Price(this.state.avaluoReciclaje)}</strong>
						</div>
					</div>

					<div className="card">
						<div className="item-widget">
							<span>Puntaje</span>
							<strong>{this.state.puntaje}</strong>
						</div>
					</div>

					<div className="card">
						<div className="item-widget">
							<span>Calificación</span>
							<strong>
								{this.state.data.calificacion ? (
									<img
										src={require(`../../img/${this.state.data.calificacion}`)}
										alt={`Calificación: ${this.state.data.calificacion}`}
										width={18}
										height={18}
									/>
								) : (
									<>Sin calificar</>
								)}
							</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<div className="contenido">
								<h3>Detalle de la solicitud</h3>
								<p>
									<strong>Fecha: </strong>
									{this.state.data.fecha}
								</p>
								<p>
									<strong>Ciudad: </strong>
									{this.state.data.cityName}
								</p>
								<p>
									<strong>Barrio: </strong>
									{this.state.data.neighborhoodName}
								</p>
								<p>
									<strong>E.C.A.: </strong>
									{this.state.data.eca?.name}
								</p>
								<p>
									<strong>Dirección: </strong>
									{this.state.data.address}
								</p>
								<p>
									<strong>Puntos de referencia: </strong>
									{this.state.data.reference}
								</p>
								<p>
									<strong>Recolector: </strong>
									{this.state.data.collector?.name}
								</p>
								<p>
									<strong>Disponibilidad: </strong>
									{this.state.data.timaAvailability}
								</p>
							</div>
						</div>
						<div className="column">
							{this.state.estadoOriginal !== 'Recolección finalizada' ? (
								<Input
									type="select"
									title="Estado de la solicitud"
									value={this.state.status}
									change={val => this.setState({ status: val })}
									options={[
										{ val: 'Solicitud enviada', text: 'Solicitud enviada' },
										{ val: 'Recolección finalizada', text: 'Recolección finalizada' }
									]}
								/>
							) : (
								<div className="contenido">
									<p>
										<strong>Estado de la solicitud: </strong>
									</p>
									<p>{this.state.status}</p>
								</div>
							)}
							<div className="contenido">
								<p>
									<strong>Calificación: </strong>
								</p>
								{this.state.data.calificacion ? (
									<img
										src={require(`../../img/${this.state.data.calificacion}`)}
										alt={`Calificación: ${this.state.data.calificacion}`}
										width={18}
										height={18}
									/>
								) : (
									<p>El usuario aún no ha calificado el servicio</p>
								)}
							</div>
						</div>
					</div>
					<div className="divider"></div>
					<div className="columns">
						<div className="column">
							<h3>Residuos</h3>
						</div>
						<div className="column" style={{ textAlign: 'right' }}>
							{this.state.estadoOriginal !== 'Recolección finalizada' && (
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.setState({ modalResiduos: true })
									}}
									style={{ display: 'inline-block' }}
									className="btn">
									Añadir residuos
								</a>
							)}
						</div>
					</div>
					<br />
					<table className="table">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Detalles</th>
								<th>Unidad Medida</th>
								<th>Cantidad recolectada</th>
								<th>Puntos</th>
								{this.state.estadoOriginal !== 'Recolección finalizada' && <th>Acciones</th>}
							</tr>
						</thead>
						<tbody>
							{this.state.wastesSelected.map(waste => (
								<tr key={waste.uuid}>
									<td>{waste.title}</td>
									<td>{waste.detalles}</td>
									<td>{this.state.wastes.find(wastes => wastes.uuid === waste.uuid)?.unit}</td>
									<td>
										<Input
											type="number"
											value={this.state.wastesSelected.find(wastes => wastes.uuid === waste.uuid)?.recolected}
											change={val => this.changeCantidadRecolectada(waste.uuid, val)}
											disabled={this.state.estadoOriginal === 'Recolección finalizada'}
										/>
									</td>
									<td>{this.getPoints(waste.uuid)}</td>
									{this.state.estadoOriginal !== 'Recolección finalizada' && (
										<td>
											<a
												href="#"
												onClick={event => {
													event.preventDefault()
													this.eliminarResiduo(waste.uuid)
												}}
												className="btn-delete">
												Eliminar
											</a>
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default withParams(Page)
