import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import { Alert, parseDateStringToNumber } from 'utiles/functions'
const needCollector = new DB('need_collector')
let allNeedCollectors = []

class Index extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: []
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			const needCollectorResult = await needCollector.getAll()
			if (needCollectorResult.status !== true) {
				console.log(needCollectorResult)
				this.setState({ loading: false })
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			allNeedCollectors = needCollectorResult.data.Items
			// Hacemos un sort de las recolleciones, por fecha, en orden decendente
			allNeedCollectors.sort((a, b) => {
				const dateA = parseDateStringToNumber(a.fecha)
				const dateB = parseDateStringToNumber(b.fecha)
				if (dateA > dateB) {
					return -1
				}
				if (dateA < dateB) {
					return 1
				}
				return 0
			})
			this.setState({ data: needCollectorResult.data.Items, loading: false })
		} catch (error) {
			console.log(error)
			this.setState({ loading: false })
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<h2>Recolección</h2>
						<span>Lista</span>
					</div>
					<div className="btns"></div>
				</div>
				<div className="grid g5" style={{ marginBottom: 20 }}>
					<div className="card">
						<div className="item-widget">
							<span>Total solicitudes</span>
							<strong>{allNeedCollectors.length}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Solicitudes pendientes</span>
							<strong>{allNeedCollectors.filter(s => s.status === 'Solicitud enviada').length}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Solicitudes finalizadas</span>
							<strong>{allNeedCollectors.filter(s => s.status === 'Recolección finalizada').length}</strong>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input
								type="text"
								placeholder="Escribe algo.."
								onChange={event => {
									const val = event.target.value
									if (val === '' || val === null || val === undefined) {
										this.setState({ data: allNeedCollectors })
										return
									}
									if (val.length < 3) {
										return
									}
									const filtered = allNeedCollectors.filter(c => {
										if (!c.eca?.name || !c.user?.name) {
											return false
										}
										const userName = c.user.name.toLowerCase()
										const nameEca = c.eca.name.toLowerCase()
										const status = c.status.toLowerCase()
										if (
											userName.indexOf(val.toLowerCase()) > -1 ||
											nameEca.indexOf(val.toLowerCase()) > -1 ||
											status.indexOf(val.toLowerCase()) > -1
										) {
											return true
										}
										return false
									})
									this.setState({ data: filtered })
								}}
							/>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>E.C.A</th>
								<th>Dirección</th>
								<th>Usuario</th>
								<th>Estado</th>
								<th>Fecha</th>
								<th>residuos</th>
								<th>Puntos</th>
								<th>Calificación</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(collector => (
								<tr key={collector.uuid}>
									<td>{collector.eca.name}</td>
									<td style={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>{collector.address}</td>
									<td>{collector.user.name}</td>
									<td>{collector.status}</td>
									<td>{collector.fecha}</td>
									<td style={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>
										{collector.wastesSelected.map((waste, i) => (
											<p key={collector.uuid + '_' + i}>{waste.title}, </p>
										))}
									</td>
									<td>{collector.points}</td>
									<td>
										{collector.calificacion && (
											<img src={require(`img/${collector.calificacion}`)} alt={`Calificación: ${collector.calificacion}`} width={18} height={18} />
										)}
									</td>

									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('admin/need_collector/' + collector.uuid)
											}}
											className="btn-edit">
											Gestionar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Index)
