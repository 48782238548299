import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage } from 'utiles/functions'
import DB from 'utiles/db'
import { v4 as uuidv4 } from 'uuid'
const banners = new DB('banners')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true,
			banner: ''
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)

		// guardar imagen en el bucket
		if (this.state.banner !== '') {
			this.setState({ loading: true }, async () => {
				try {
					const url = await uploadImage(this.state.banner, 'slider_')
					const slider = {
						uuid: uuidv4(),
						link: data.get('link'),
						image: url,
						status: this.state.status
					}
					const res = await banners.create(slider)
					if (res.status) {
						this.setState({ loading: false })
						this.props.navigate('admin/banners')
						Alert('Banner registrado correctamente.')
					} else {
						console.log(res)
						this.setState({ loading: false })
						Alert('¡Ooops!, tenemos un problemas para crear el banner, intenta de nuevo', 'danger')
					}
				} catch (err) {
					console.log(err)
					this.setState({ loading: false })
					Alert('¡Ooops!, tenemos un problemas para crear el banner, intenta de nuevo', 'danger')
				}
			})
		} else {
			Alert('Selecciona una imagen', 'danger')
		}
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<form action="#" onSubmit={this.handleSubmit}>
					<div className="title-page">
						<div className="title">
							<NavLink to="/admin/banners" className="back">
								<img src={require('img/back.svg').default} width={25} height={25} />
							</NavLink>
							<h2>Banner de imagenes</h2>
							<span>Nueva imagen</span>
						</div>
						<div className="btns">
							<button className="btn" type="submit">
								Guardar
							</button>
						</div>
					</div>
					<div className="card">
						<div className="columns">
							<div className="column" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
								<Input
									type="image"
									title="Imagen (600 x 350)"
									style={{ width: 400, height: 250, borderRadius: 4 }}
									aspect={{ width: 600, height: 350 }}
									img={this.state.banner}
									change={image => this.setState({ banner: image })}
								/>
								<Input type="text" title="Link de redirección (Opcional)" placeholder="https://" name="link" />
								<Input
									type="checkbox"
									title="Estado (Inactiva / Activa)"
									name="status"
									change={v => this.setState({ status: v })}
									active={this.state.status}
								/>
							</div>
						</div>
						<div className="divider"></div>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</>
		)
	}
}
