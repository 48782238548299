import { NavLink } from 'react-router-dom'
export default function Header(props) {
	return (
		<header>
			<div>
				<a
					href="#"
					onClick={event => {
						event.preventDefault()
						props.setMini(!props.mini)
					}}>
					<img className="toggle-menu" src={require('img/menu.svg').default} width={35} height={35} />
				</a>
			</div>
			<div className="container-access">
				{/* <a href="#" className="notifications">
	            	<img src={require('img/notification.svg').default} width={20} height={20}/>
	            </a> */}
				<NavLink to="/admin/settings" className="settings">
					<img src={require('img/settings.svg').default} width={20} height={20} />
				</NavLink>
				<NavLink className="avatar" to="/dashboard/profile">
					<span className="container-avater">{props.user.name[0].toUpperCase()}</span>
					<div className="text">
						<span>{props.user.name}</span>
					</div>
				</NavLink>
			</div>
		</header>
	)
}
