import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage, deleteImage } from 'utiles/functions'
import withParams from 'componentes/WithParams'
import DB from 'utiles/db'
const banners = new DB('banners')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true,
			banner: '',
			link: '',
			urlImagen: ''
		}
	}

	componentDidMount() {
		banners.get({ uuid: this.props.params.uuid }).then(data => {
			if (data.status) {
				this.setState({
					loading: false,
					link: data.data.Item.link,
					status: data.data.Item.status,
					urlImagen: data.data.Item.image
				})
			} else {
				console.log(data)
				this.setState({ loading: false })
				Alert('¡Ooops!, tenemos un problemas para cargar el banner, intenta de nuevo', 'danger')
			}
		})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)

		// guardar imagen en el bucket

		this.setState({ loading: true }, async () => {
			try {
				const slider = {
					link: data.get('link'),
					image: this.state.urlImagen,
					status: this.state.status
				}

				// eliminar imagen
				if (this.state.banner !== '') {
					await deleteImage(this.state.urlImagen)
					const url = await uploadImage(this.state.banner, 'slider_')
					slider.image = url
				}

				banners
					.update(slider, { uuid: this.props.params.uuid })
					.then(result => {
						if (result.status) {
							this.setState({ loading: false })
							this.props.navigate('admin/banners')
							Alert('Banner actualizado correctamente.')
						} else {
							console.log(result)
							this.setState({ loading: false })
							Alert('¡Ooops!, tenemos un problemas para actualizar el banner, intenta de nuevo', 'danger')
						}
					})
					.catch(err => {
						console.log(err)
						this.setState({ loading: false })
						Alert('¡Ooops!, tenemos un problemas para actualizar el banner, intenta de nuevo', 'danger')
					})
			} catch (err) {
				console.log(err)
				this.setState({ loading: false })
				Alert('¡Ooops!, tenemos un problemas para crear el banner, intenta de nuevo', 'danger')
			}
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<form action="#" onSubmit={this.handleSubmit}>
					<div className="title-page">
						<div className="title">
							<NavLink to="/admin/banners" className="back">
								<img src={require('img/back.svg').default} width={25} height={25} />
							</NavLink>
							<h2>Banner de imagenes</h2>
							<span>Editar imagen</span>
						</div>
						<div className="btns">
							<button className="btn" type="submit">
								Guardar
							</button>
						</div>
					</div>
					<div className="card">
						<div className="columns">
							<div className="column" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
								<Input
									type="image"
									title="Imagen (600 x 350)"
									style={{ width: 400, height: 250, borderRadius: 4 }}
									aspect={{ width: 600, height: 350 }}
									img={this.state.urlImagen}
									change={image => this.setState({ banner: image })}
								/>
								<Input type="text" title="Link de redirección (Opcional)" placeholder="https://" name="link" value={this.state.link} />
								<Input
									type="checkbox"
									title="Estado (Inactiva / Activa)"
									name="status"
									change={v => this.setState({ status: v })}
									active={this.state.status}
								/>
							</div>
						</div>
						<div className="divider"></div>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</>
		)
	}
}

export default withParams(Page)
