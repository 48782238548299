import e from './settings'
const AWS = require('aws-sdk')
AWS.config.update({
	region: e.region,
	accessKeyId: e.accessKeyId,
	secretAccessKey: e.secretAccessKey,
	dynamoDbCrc32: false
})
const Dynamo = new AWS.DynamoDB.DocumentClient()

class DB {
	constructor(table) {
		this.table = table
		this.nextKey = null
		this.prevKey = null
		this.limit = 10
	}

	count(FilterExpression, ExpressionAttributeValues, ExpressionAttributeNames) {
		return new Promise(resolve => {
			Dynamo.scan(
				{
					TableName: this.table,
					FilterExpression,
					ExpressionAttributeValues,
					ExpressionAttributeNames,
					Select: 'COUNT'
				},
				function (err, result) {
					if (err) {
						resolve({ status: false, data: err })
					} else {
						resolve({ status: true, data: result })
					}
				}
			)
		})
	}

	query(expression, data, names) {
		return new Promise(resolve => {
			Dynamo.scan(
				{
					TableName: this.table,
					FilterExpression: expression,
					ExpressionAttributeValues: data,
					ExpressionAttributeNames: names,
					ScanIndexForward: true
				},
				function (err, result) {
					if (err) {
						resolve({ status: false, data: err })
					} else {
						result.Items = result.Items.sort((a, b) => b.time - a.time)
						resolve({ status: true, data: result })
					}
				}
			)
		})
	}

	getAll() {
		return new Promise(resolve => {
			Dynamo.scan({ TableName: this.table, ScanIndexForward: true }, function (err, result) {
				if (err) {
					resolve({ status: false, data: err })
				} else {
					result.Items = result.Items.sort((a, b) => b.createdAt - a.createdAt)
					resolve({ status: true, data: result })
				}
			})
		})
	}

	// ✓
	get(data) {
		return new Promise(resolve => {
			Dynamo.get({ TableName: this.table, Key: data }, function (err, result) {
				if (err) {
					resolve({ status: false, data: err })
				} else {
					resolve({ status: true, data: result })
				}
			})
		})
	}

	paginate(limit = 10) {
		return new Promise(resolve => {
			this.limit = limit
			const setNextKey = key => {
				this.nextKey = key
			}
			this.count().then(us => {
				Dynamo.scan(
					{
						TableName: this.table,
						Limit: limit,
						ScanIndexForward: true
					},
					function (err, result) {
						if (err) {
							resolve({ status: false, data: err })
						} else {
							setNextKey(result.LastEvaluatedKey)
							result.total = us.data.Count
							resolve({ status: true, data: result })
						}
					}
				)
			})
		})
	}

	next() {
		return new Promise(resolve => {
			const setNextKey = key => {
				this.nextKey = key
			}
			Dynamo.scan(
				{
					TableName: this.table,
					Limit: this.limit,
					ExclusiveStartKey: this.nextKey
				},
				function (err, result) {
					if (err) {
						resolve({ status: false, data: err })
					} else {
						setNextKey(result.LastEvaluatedKey)
						resolve({ status: true, data: result })
					}
				}
			)
		})
	}

	// // ✓
	// id() {
	// 	var CUSTOMEPOCH = 1300000000000; // artificial epoch
	// 	var ts = new Date().getTime() - CUSTOMEPOCH; // limit to recent
	// 	return ts;
	// }

	// read(id) {
	// 	return new Promise((resolve) => {
	// 		Dynamo.get({TableName: this.table, Key: {"id": id}}, function(err, data){
	// 			if(err) {
	// 				reject({status: false, data: err});
	// 			}else {
	// 				resolve({status: true, data: data});
	// 			}
	// 		})
	// 	})
	// }

	// // ✓
	oldUpdate(params) {
		return new Promise(resolve => {
			params.TableName = this.table
			Dynamo.update(params, (err, data) => {
				if (err) {
					resolve({ status: false, data: err })
				} else {
					resolve({ status: true, data })
				}
			})
		})
	}

	update(data, dKey) {
		return new Promise(resolve => {
			let expresion = 'SET '
			const values = {}
			const attributes = {}
			for (const key of Object.keys(data)) {
				const exp = key === 'name' ? '#n' : '#' + key
				const expV = key === 'name' ? ':n' : ':' + key
				expresion += exp + ' = ' + expV + ', '
				values[expV] = data[key]
				attributes[exp] = key
			}
			expresion = expresion.slice(0, -2)
			Dynamo.update(
				{
					Key: dKey,
					TableName: this.table,
					UpdateExpression: expresion,
					ExpressionAttributeValues: values,
					ExpressionAttributeNames: attributes
				},
				(err, data) => {
					if (err) {
						resolve({ status: false, data: err })
					} else {
						resolve({ status: true, data })
					}
				}
			)
		})
	}

	delete(data) {
		return new Promise(resolve => {
			Dynamo.delete({ TableName: this.table, Key: data }, (err, data) => {
				if (err) {
					resolve({ status: false, data: err })
				} else {
					resolve({ status: true, data })
				}
			})
		})
	}

	// ✓
	// Obtener registro por clave primaria
	find(data) {
		return new Promise(resolve => {
			Dynamo.get({ TableName: this.table, Key: data }, function (err, result) {
				if (err) {
					resolve({ status: false, data: err })
				} else {
					resolve({ status: true, data: result })
				}
			})
		})
	}

	// ✓
	// Crear un registro en la base de datos
	create(data) {
		return new Promise(resolve => {
			data.createdAt = this.time()
			data.updatedAt = this.time()
			Dynamo.put({ TableName: this.table, Item: data }, function (err, data) {
				if (err) {
					resolve({ status: false, data: err })
				} else {
					resolve({ status: true, data })
				}
			})
		})
	}

	time() {
		const date = new Date()
		return (
			date.getDate() +
			'-' +
			(date.getMonth() + 1) +
			'-' +
			date.getFullYear() +
			' ' +
			date.getHours() +
			':' +
			date.getMinutes() +
			':' +
			date.getSeconds()
		)
	}
}
export default DB
