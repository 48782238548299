import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import AnimateScreen from 'componentes/animateScreen'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import e from 'utiles/settings'
const levels = new DB('levels')
const users = new DB('users')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: [],
			levels: []
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		const niveles = (await levels.getAll()).data.Items
		let ranking = (await users.query('#points > :0', { ':0': 0 }, { '#points': 'points' }, 2500)).data.Items
		ranking = ranking.sort((a, b) => Number(b.points) - Number(a.points))
		this.setState({ levels: niveles, data: ranking, loading: false })
	}

	getLevelByPoints(points) {
		const level = this.state.levels.find(l => Number(points) <= Number(l.points))
		return level
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/levels" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Niveles</h2>
						<span>Ranking</span>
					</div>
				</div>
				<div className="card">
					<table className="table">
						<thead>
							<tr>
								<th>Usuario</th>
								<th>Puntos</th>
								<th>Nivel</th>
								<th>Correo</th>
								<th>Teléfono</th>
								<th>Eca</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map((user, i) => (
								<tr key={user.user}>
									<td>
										<div className="item-pro">
											{user.avatar ? <img src={e.urlBucket + user.avatar} alt={user.name} /> : <img src={require('img/user.png')} alt={user.name} />}
											<span>{user.name}</span>
											{i === 0 && <img src={require('img/medal1.png')} style={styleImage} />}
											{i === 1 && <img src={require('img/medal2.png')} style={styleImage} />}
											{i === 2 && <img src={require('img/medal3.png')} style={styleImage} />}
										</div>
									</td>
									<td>
										<div className="item-pro">
											<img src={e.urlBucket + this.getLevelByPoints(user.points).icono} alt={'Icono'} style={styleImgLevel} />
											{this.getLevelByPoints(user.points).name || '...'}
										</div>
									</td>
									<td>
										<strong>{user.points}</strong>
									</td>
									<td>{user.user}</td>
									<td>{user.phone || '...'}</td>
									<td>{user?.eca?.name || '...'}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												console.log(user)
											}}
											className="btn-edit">
											Ver usuario
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

const styleImage = {
	width: 25,
	height: 25,
	backgroundColor: 'transparent',
	marginLeft: 7
}
const styleImgLevel = {
	width: 30,
	height: 30
}
