import { Component } from 'react'
import AnimateScreen from 'componentes/animateScreen'

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: []
		}
	}

	render() {
		return (
			<>
				<div className="title-page">
					<div className="title">
						<a href="#" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</a>
						<h2>Title Page</h2>
						<span>Subtitle</span>
					</div>
					<div className="btns">
						<a href="#" className="btn">
							New item
						</a>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Column 1</th>
								<th>Column 2</th>
								<th>Column 3</th>
								<th>Column 4</th>
								<th>Column 5</th>
								<th>Column 6</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
							</tr>
							<tr>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
								<td>#4</td>
							</tr>
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)
